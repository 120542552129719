import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "../../components/button";
import Modal from "../../components/modal";
import DeleteConfirm from "../../components/deleteConfirm";
import Paper from "../../components/paper";
import CustomSelect from "../../components/select";
import { RxCross2 } from "react-icons/rx";
import { MdDeleteOutline } from "react-icons/md";
import Table from "../../components/table";
import Title from "../../components/title";
import breakLine from "../../utils/breakLine";
import { toastContext } from "../../components/toast";
import { BodyRelacao, DadosFuncionarios, DadosRelacao } from "../../types";
import useRelacao from "../../hooks/useQuery/useRelacao";
import useFuncionarios from "../../hooks/useQuery/useFuncionarios";
interface DadosCracha {
  devEUI: string;
  nome: string;
}

export default function Vinculos() {
  const [openModal, setOpenModal] = useState(false);
  const [functionDelete, setFunctionDelete] = useState<() => void>(
    () => () => {}
  );
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [subTitleDelete, setSubTitleDelete] = useState<
    string | Array<React.ReactElement>
  >("");
  const [relacoes, setRelacoes] = useState<Array<DadosRelacao>>([]);
  const [funcionarioVincular, setFuncionarioVincular] = useState<
    string | number
  >();
  const [crachaVincular, setCrachaVincular] = useState<string | number>();
  const [funcionariosOptions, setFuncionariosOptions] = useState<
    Array<{ label: string | number; value: string | number }>
  >([]);
  const [crachasOptions, setCrachasOptions] = useState<
    Array<{ label: string | number; value: string | number }>
  >([]);
  const [funcionarios, setFuncionarios] = useState<
    Record<number | string, DadosFuncionarios>
  >({});
  const [rows, setRows] = useState<Array<[string, string, JSX.Element]>>([]);
  const toastCall = useContext(toastContext).toastCall as Function;
  const {
    postRelacao,
    deleteRelacao,
    data: dataRelacao,
    dataSemRelacao,
  } = useRelacao({
    onSuccessDelete: (index: number) => {
      toastCall("Vínculo desfeito com sucesso");
    },
    onErrorPost: (err) => {
      toastCall("Erro, Por favor tente mais tarde");
      setOpenModal(false);
    },
    onSuccessPost: (data) => {
      toastCall("Vínculo realizado com sucesso");
      setRelacoes([
        ...relacoes,
        {
          crachaId: crachaVincular as string,
          funcionarioId: funcionarioVincular as number,
          createdAt: new Date().toString(),
        },
      ]);

      setOpenModal(false);
    },
    onSuccess: (relacoes: Array<DadosRelacao>) => {
      console.log(relacoes, "relacoes");
    },
  });
  useEffect(() => {
    {
      const crachasOptionsAux: typeof crachasOptions = [];
      const funcionariosAux: typeof funcionarios = {};
      const funcionariosOptionsAux: typeof funcionariosOptions = [];
      dataSemRelacao?.funcionarios.forEach((funcionario) => {
        funcionariosAux[funcionario.id] = funcionario;
        funcionariosOptionsAux.push({
          value: funcionario.id,
          label: funcionario.nome,
        });
      });
      setFuncionariosOptions(funcionariosOptionsAux);
      setFuncionarioVincular(funcionariosOptionsAux?.[0]?.value);
      dataSemRelacao?.crachas.forEach((cracha) => {
        crachasOptionsAux.push({ value: cracha.devEUI, label: cracha.nome });
      });
      setCrachasOptions(crachasOptionsAux);
      setCrachaVincular(crachasOptionsAux?.[0]?.value);
    }
  }, [dataSemRelacao]);

  useEffect(() => {
    setRelacoes(dataRelacao || []);
  }, [dataRelacao]);

  const { data: dataFuncionario } = useFuncionarios();
  useEffect(() => {
    console.log("func on success");
    const funcionariosAux: typeof funcionarios = {};
    const funcionariosOptionsAux: typeof funcionariosOptions = [];
    dataFuncionario?.forEach((funcionario) => {
      funcionariosAux[funcionario.id] = funcionario;
      funcionariosOptionsAux.push({
        value: funcionario.id,
        label: funcionario.nome,
      });
    });
    console.log("setandoFuncionarios");
    setFuncionarios(funcionariosAux);
  }, [dataFuncionario]);
  const attRows = useCallback(() => {
    const rowsAux: typeof rows = [];
    relacoes.forEach((relacao: DadosRelacao, index) => {
      if (funcionarios[relacao.funcionarioId])
        rowsAux.push([
          funcionarios[relacao.funcionarioId].nome,
          relacao.crachaId,
          <MdDeleteOutline
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenModalDelete(true);
              setSubTitleDelete(
                breakLine(
                  `Tem certeza que deseja remover a relação:<br/>
                ${funcionarios[relacao.funcionarioId].nome} - ${
                    relacao.crachaId
                  }?`
                )
              );
              setFunctionDelete(() => () => {
                deleteRelacao(relacao?.funcionarioId as number);
              });
            }}
          />,
        ]);
    });
    console.log("rows", rowsAux, funcionarios);
    setRows(rowsAux);
  }, [funcionarios, relacoes]);
  useEffect(() => {
    console.log("attrows");
    attRows();
  }, [attRows, funcionarios, relacoes]);
  return (
    <>
      <DeleteConfirm
        deleteFunc={functionDelete}
        subtitle={subTitleDelete}
        onClose={() => {
          setOpenModalDelete(false);
        }}
        visibility={openModalDelete}
      />
      <Modal visibility={openModal}>
        <Paper
          style={{
            display: "flex",
            width: "50rem",
            height: "30rem",
            position: "relative",
            flexDirection: "column",
            borderRadius: "2px",
            paddingBottom: "2rem",
            boxShadow: "1px 1px 4px rgba(0,0,0,0.3)",
          }}
        >
          <div style={{ padding: "2rem" }}>
            <RxCross2
              style={{ position: "absolute", right: "2rem", cursor: "pointer" }}
              onClick={() => {
                setOpenModal(false);
              }}
              size={30}
              color="rgb(65, 13, 91)"
            />
            <Title value="Adicionar vínculo" />
            <div style={{ margin: "3rem 5rem 0 5rem" }}>
              <CustomSelect
                onChange={(funcionario) => {
                  setFuncionarioVincular(funcionario);
                }}
                label="Nome funcionário"
                value={funcionarioVincular}
                options={funcionariosOptions}
                style={{
                  border: "1px solid #979797",
                  borderRadius: "16px",
                  marginBottom: "2rem",
                }}
              />
              <CustomSelect
                value={crachaVincular}
                onChange={(cracha) => {
                  setCrachaVincular(cracha);
                }}
                options={crachasOptions}
                label="Crachá"
                style={{ border: "1px solid #979797", borderRadius: "16px" }}
              />
            </div>
          </div>
          <div
            style={{
              padding: "2rem",
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              onClick={() => {
                const data = {
                  crachaId: crachaVincular,
                  funcionarioId: funcionarioVincular,
                } as BodyRelacao;
                postRelacao(data);
              }}
              label="Salvar"
            />
          </div>
        </Paper>
      </Modal>

      <Paper
        style={{
          display: "flex",
          padding: "1rem 0rem 3rem 0rem",
          flexDirection: "column",
          boxShadow: "0.5px 0.5px 1px rgba(0,0,0,0.05)",
        }}
      >
        <div style={{ marginBottom: "3rem" }}>
          <Title value="Vínculos" />
        </div>
        <div style={{ padding: "0 3rem" }}>
          {useMemo(
            () => (
              <Table
                rows={rows}
                columns={[
                  { name: "Nome do funcionário", size: 1 },
                  { name: "Id crachá", size: 1 },
                  { name: "Options", size: 1 },
                ]}
              />
            ),
            [rows]
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "2rem",

            marginTop: "4rem",
          }}
        >
          <Button
            onClick={() => {
              setOpenModal(true);
            }}
            style={{
              backgroundColor: "white",
              border: "1px solid #410D5B",
              color: "#410D5B",
              outline: "none",
              textAlign: "center",
              minWidth: "10rem",
              marginRight: "2rem",
            }}
            label="Adicionar vínculo"
          />
        </div>
      </Paper>
    </>
  );
}
