import React, { useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import DatePicker from "../../components/datePicker";
import MultiSelect from "../../components/multiSelect";
import Paper from "../../components/paper";
import Table from "../../components/table";
import Title from "../../components/title";
import { useToast } from "../../components/toast";
import useFuncionarios from "../../hooks/useQuery/useFuncionarios";
import useRelatorio from "../../hooks/useQuery/useRelatorio";
import { BodyRelatorio, DadosFuncionarios } from "../../types";
import {
  formatDateOrDateStringToStringWithTime,
  generateAdjustedTimeAndDateByDay,
  getDateFromISOStringOrDate,
} from "../../utils/dates.utils";

export default function RelatorioFuncionario() {
  const toast = useToast();
  const [rows, setRows] = useState<Array<[string, string, string, string]>>([]);

  /*async function buscarDados() {
    
  }
*/
  const INITIAL_DATE = useMemo(() => generateAdjustedTimeAndDateByDay(), []);

  const [optionsFuncionarios, setOptionsFuncionarios] = useState<
    Record<string | number, string | number>
  >({});
  const [funcionariosSelected, setFuncionariosSelected] = useState<
    typeof optionsFuncionarios
  >({ todos: "Todos" });
  useFuncionarios({
    onSuccess: (funcionarios) => {
      const optionsAux: typeof optionsFuncionarios = {};
      funcionarios.forEach((funcionario) => {
        optionsAux[funcionario.id] = funcionario.nome;
      });
      setOptionsFuncionarios(optionsAux);
    },
  });
  const [data, setData] = useState(INITIAL_DATE[2]);
  const [de, setDe] = useState(INITIAL_DATE[1]);
  const [ate, setAte] = useState(INITIAL_DATE[0]);
  const query = useMemo(() => {
    return `de=${data}T${de}&ate=${data}T${ate}`;
  }, [data, de, ate]);
  const filter: BodyRelatorio = useMemo(() => {
    if ("todos" in funcionariosSelected) {
      return {
        funcs: [],
        areas: [],
      };
    }
    const funcionariosID = Object.values(funcionariosSelected).map((value) => {
      return value as number;
    });

    return {
      funcs: funcionariosID,
      areas: [],
    };
  }, [funcionariosSelected]);
  useRelatorio({
    query,
    body: filter,
    onSuccess: (dataRelatorios) => {
      const rowsAux: typeof rows = [];
      Object.entries(dataRelatorios.funcs).forEach(([funcName, dataFuncs]) => {
        dataFuncs.forEach((dataFunc) => {
          rowsAux.push([
            funcName,
            dataFunc.area,
            formatDateOrDateStringToStringWithTime(dataFunc?.de),
            formatDateOrDateStringToStringWithTime(dataFunc?.ate),
          ]);
        });
      });
      setRows(rowsAux);
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        columnGap: "3rem",
        rowGap: "3rem",
        paddingLeft: "1rem",
      }}
    >
      <Paper
        style={{
          flex: 1,
          minHeight: "100vh",
          backgroundColor: "white",
        }}
      >
        <Title
          style={{ marginTop: "5rem" }}
          value="Relatório de funcionários"
        />
        <div
          style={{
            padding: "0 2rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              rowGap: "1.5rem",
              flexDirection: "column",
              maxWidth: "fit-content",
            }}
          >
            <label>
              <span
                style={{
                  fontSize: "1rem",
                  fontFamily: "inter",
                  fontWeight: 500,
                }}
              >
                Data
              </span>
            </label>
            <DatePicker
              defaultDateValue={new Date(`${data}T12:00`)}
              defaultTimeStartValue={de}
              defaultTimeEndValue={ate}
              onConfirm={(value) => {
                setData(getDateFromISOStringOrDate(value.date));
                setDe(value?.startTime as string);
                setAte(value?.endTime as string);
              }}
              timePicker
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "1rem",
              flex: 1,
            }}
          >
            <label
              style={{ fontSize: "1rem", fontFamily: "inter", fontWeight: 500 }}
            >
              Funcionários:
            </label>
            <MultiSelect
              onSelectAll={() => {
                setFuncionariosSelected({ todos: "Todos" });
              }}
              onRemoveAll={() => {
                setFuncionariosSelected({});
              }}
              selected={funcionariosSelected}
              onRemove={(value) => {
                const funcionariosSelectedCopy = { ...funcionariosSelected };
                delete funcionariosSelectedCopy[value[0]];
                setFuncionariosSelected(funcionariosSelectedCopy);
              }}
              onSelect={(value) => {
                if ("todos" in funcionariosSelected) {
                  setFuncionariosSelected({ [value[0]]: value[1] });
                  return;
                }
                const funcionariosSelectedCopy = { ...funcionariosSelected };
                funcionariosSelectedCopy[value[0]] = value[1];
                setFuncionariosSelected(funcionariosSelectedCopy);
              }}
              options={optionsFuncionarios}
            />
          </div>
          <Table
            rows={rows}
            columns={[
              { name: "Nome", size: 1 },
              { name: "Área", size: 1 },
              { name: "De", size: 1 },
              { name: "Até", size: 1 },
            ]}
          />
        </div>
      </Paper>
    </div>
  );
}
