import Paper from "../paper";
import CustomSelect from "../select";
import Timepicker from "../timepicker";
import "./styles.css";
import DatePicker from "../datePicker";
import * as CustomMultiSelect from "../multiSelect";
export default function OptionsMenu({
  ...props
}: {
  style?: React.CSSProperties;
  paperBackground?: string;
  options: Array<
    | {
        name: string;
        type: "selection" | "multiSelect";
        ops?: Array<{ label: string; value: string | number }>;
        value: any;
        onChange?: (data: string) => void;
      }
    | {
        type: "datetime";
        name: string;

        value: any;
        onChange?: (data: {
          startTime: string;
          endTime: string;
          date: Date;
        }) => void;

        time?: {
          startTime: string;
          endTime: string;
        };
      }
  >;
}) {
  function MultiSelect({
    value,
    name,
    onChange,
  }: {
    value?: string;
    name: string;
    onChange?: (time: string) => void;
  }) {
    return (
      <Paper
        style={{
          maxWidth: "fit-content",
          padding: "1.5rem 1rem",
          display: "flex",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
          alignItems: "center",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
      >
        <span style={{ marginRight: "1rem" }}>{name}</span>
        <CustomMultiSelect.default key="areas-multselect" />
      </Paper>
    );
  }
  function Time({
    value,
    name,
    onChange,
  }: {
    value?: string;
    name: string;
    onChange?: (time: string) => void;
  }) {
    return (
      <Paper
        style={{
          maxWidth: "fit-content",
          padding: "1.5rem 1rem",
          display: "flex",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
          alignItems: "center",
          backgroundColor: "rgba(255,255,255,0.7)",
        }}
      >
        <span style={{ marginRight: "1rem" }}>{name}</span>
        <Timepicker
          defaultValue={value}
          onChange={(value) => {
            onChange?.(value);
          }}
          type="time"
          className="without-ampm"
          style={{ padding: "0.2rem 0" }}
        />
      </Paper>
    );
  }
  function DateComponent({
    value,
    name,
    onChange,
    startTimeValue,
    endTimeValue,
  }: {
    value?: Date;
    name: string;
    startTimeValue?: string;
    endTimeValue?: string;
    onChange?: (value: {
      date: Date;
      startTime: string;
      endTime: string;
    }) => void;
  }) {
    return (
      <Paper
        style={{
          maxWidth: "fit-content",
          padding: "1.5rem 1rem",
          display: "flex",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
          alignItems: "center",
          flexDirection: "row",

          backgroundColor: props.paperBackground || "white",
        }}
      >
        <label style={{ marginRight: "0.5rem" }}>{name}</label>
        <DatePicker
          calendarStyleWrapper={{
            transform: "translate(-50%,0)",
          }}
          timePicker
          defaultTimeStartValue={startTimeValue}
          defaultTimeEndValue={endTimeValue}
          defaultDateValue={value ? new Date(value) : new Date()}
          id={name}
          style={{ border: "1px solid rgba(0, 0, 0, 0.4)" }}
          onConfirm={(value) => {
            onChange?.({
              date: value.date,
              startTime: value?.startTime as string,
              endTime: value?.endTime as string,
            });
          }}
        />
      </Paper>
    );
  }
  function Selection({
    value,
    name,
    options,
    onChange,
  }: {
    value?: string;
    name: string;
    options: Array<{ label: string; value: string }>;
    onChange: (user: string) => void;
  }) {
    return (
      <Paper
        style={{
          maxWidth: "fit-content",
          padding: "1.5rem 1rem",
          display: "flex",
          borderRadius: "0.5rem",
          boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: props.paperBackground || "white",
        }}
      >
        <span style={{ marginRight: "0.5rem" }}>{name}</span>
        <CustomSelect
          value={value}
          onChange={(user) => {
            onChange?.(user);
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "0.2rem 2rem",
          }}
          options={options}
        />
      </Paper>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "1rem",
        justifyContent: "space-between",
        alignItems: "center",
        ...props.style,
      }}
    >
      {props.options.map((op) => {
        if (op.type === "datetime")
          return (
            <DateComponent
              onChange={op.onChange}
              value={op.value}
              name={op.name}
              startTimeValue={op.time?.startTime}
              endTimeValue={op.time?.endTime}
            />
          );
        else if (op.type === "selection")
          return (
            <Selection
              onChange={op.onChange as () => void}
              value={op.value}
              name={op.name}
              options={op.ops as Array<{ label: string; value: string }>}
            />
          );
        else if (op.type === "multiSelect")
          return <MultiSelect name="Funcionários" />;
        return <></>;
      })}
    </div>
  );
}
