
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Card from "../../components/card";
import DatePicker from "../../components/datePicker";
import Paper from "../../components/paper";
import RadioGroup from "../../components/radioGroup";
import {useGlobalContext} from "../../context/globalContext";
import SubTitle from "../../components/subTitle";
import Table from "../../components/table";
import Title from "../../components/title";
import {
  getDateFromISOStringOrDate,
  generateAdjustedTimeAndDate,
  createDateFromTimeStringAndDateString,
  formatDateOrDateStringToStringWithTime,
} from "../../utils/dates.utils";
import MountainIcon from "../../components/mountainIcon";
import { bodyCatracaDashboard, catracaApiResponse } from "../../types";
function StatusComponent({_in}: {_in: boolean}) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "row",
            width:"100%",
            marginLeft: "1rem",
            alignItems: "center",
            columnGap: "1rem",
        }}>
            <MountainIcon color={_in ? "rgb(65, 13, 91)" : "lightgray"}/>
            <span>{_in ? "Dentro da mina": "Fora da mina"}</span>
        </div>
    )
}
function HorarioSelect({
    dataInicio, 
    dataFim, 
    tempoInicio, 
    tempoFim,
    setDataInicio,
    setDataFim,
    setTempoInicio,
    setTempoFim
    }: 
        {
            dataInicio: string, 
            dataFim: string, 
            tempoInicio: string, 
            tempoFim: string,
            setDataInicio: React.Dispatch<React.SetStateAction<string>>,
            setDataFim: React.Dispatch<React.SetStateAction<string>>,
            setTempoInicio: React.Dispatch<React.SetStateAction<string>>,
            setTempoFim: React.Dispatch<React.SetStateAction<string>>
        }) 
    {
    return <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <label
                  style={{
                    marginRight: "1rem",
                  }}
                >
                  Horário
                </label>
                <DatePicker
                  id="data-picker-horario-catraca"
                  range={true}
                  timePicker
                  defaultTimeStartValue={tempoInicio}
                  defaultTimeEndValue={tempoFim}

                  defaultDateValue={
                    new Date(
                      createDateFromTimeStringAndDateString(
                        dataInicio,
                        tempoInicio
                      )
                    )
                  }
                  onConfirm={({ date, startTime, endTime }) => {
                    setDataInicio(getDateFromISOStringOrDate(date));
                    setTempoInicio(startTime as string);
                    setDataFim(getDateFromISOStringOrDate(date));
                    setTempoFim(endTime as string);
                  }}
                />
              </div>
}
export default function Catraca() {
    const {socket} = useGlobalContext()
    const initialDateTime = useMemo(() => {
    return generateAdjustedTimeAndDate();
  }, []);
  const [dataInicio, setDataInicio] = useState(initialDateTime[2]);
  const [tempoInicio, setTempoInicio] = useState(initialDateTime[1]);
  const [tempoFim, setTempoFim] = useState(initialDateTime[0]);
  const [dataFim, setDataFim] = useState(initialDateTime[2]);
  const [selectTimeNow, setSelectTimeNow] = useState<"Agora"|"Select">("Agora");
  const refIntervalFetchDadosAgora = useRef<NodeJS.Timer|undefined>(undefined);

  useEffect(()=>{
        function fetchData(e:MessageEvent){
            //deal with live data if select time is set to agora (selectTimeNow) 
                const response:catracaApiResponse = JSON.parse(e.data)

                const data = response.payload.reports
                const countVeiculos= response.payload.count
                const countPassageiros = response.payload.passengers
                const newRows:typeof rows = []
                console.log(data,'data')
                data.forEach((value:any)=>{
                    newRows.push([<StatusComponent _in={true} />,
                                 value.vehicle.id,
                                 value.driver.name,
                                 value.persons.length,
                                 formatDateOrDateStringToStringWithTime(value.events.in.timestamp),
                                 formatDateOrDateStringToStringWithTime(value.events.out.timestamp)])          
                })
                setRows(newRows)
                updateCards(countVeiculos,countVeiculos,countPassageiros)
            //ignore message if not select time is set to select (selectTimeNow)
        }
        socket?.addEventListener("message", fetchData)
        return ()=>{
            socket?.removeEventListener("message", fetchData)            
        }
    },[])
    useEffect(()=>{
        if(selectTimeNow === "Select"){
            if(refIntervalFetchDadosAgora.current) clearInterval(refIntervalFetchDadosAgora.current)
            socket?.send(JSON.stringify({
                "route": "dashboard",
                "header": {
                    "from": createDateFromTimeStringAndDateString(dataInicio,tempoInicio).toISOString(),
                    "until": createDateFromTimeStringAndDateString(dataInicio,tempoFim).toISOString()
                },
                "payload": {}                
            }))
            return
        }
        const sendMessageWithAgoraTime = (()=>{

            const dateNow = new Date()
            console.log("update dash")
            socket?.send(
                JSON.stringify({
                  "route": "dashboard",
                  "header": {
                      "from": new Date(dateNow.getTime() - 120000).toISOString(), // 2 minutes ago
                      "until": dateNow.toISOString() // now
                  },
                  "payload": {}
                })
            )
        })
        sendMessageWithAgoraTime()
        refIntervalFetchDadosAgora.current = setInterval(sendMessageWithAgoraTime,1000 * 60) //update every minute
    },[selectTimeNow,dataInicio,tempoInicio,tempoFim])
    const updateCards = useCallback((veiculosDentro:number,veiculosFora:number,TotalPassageiros:number)=>{
        setDataCards((oldValue)=>{
            return {
                "0":{...oldValue["0"],quant:veiculosDentro},
                "1":{...oldValue["1"],quant:veiculosFora},
                "2":{...oldValue["2"],quant:TotalPassageiros}
            }
        })
  },[])
  const [dataCards, setDataCards] = useState<
    Record<
      string,
      { nome: string; quant: number; color: string; id: string | number }
    >
    >({
        "0": {
            nome: "Veículos dentro da mina",
            quant: 0,
            color: "173,216,230",
            id: "0",            
        },
       "1": {
            nome: "Total de motoristas",
            quant: 0,
            color: "144,238,144",
            id: "3",            
        },

        "2": {
            nome: "Total de passageiros",
            quant: 0,
            color: "252,190,17",
            id: "1",
        },
         });
         const [rows, setRows] = useState<Array<[ReactElement<any,any>,string,string,number,string,string]>>([]);
         
  const getDataStringValueDetalhamento = useCallback(
      ()=>{
           if(selectTimeNow === "Select")
               return document.getElementById("data-picker-horario-catraca")?.innerHTML; 
           return "Agora"
      },[selectTimeNow]
  )
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "3rem",
      }}
    >
      <Paper
        style={{
          maxWidth: "100vw",
          borderRadius: "30px",
          padding: "1rem",
          display: "flex",
          paddingBottom: "2rem",
          flexDirection: "column",
          justifyContent: "space-around",
          boxShadow: "0.5px 0.5px  6px 0.5px rgba(0,0,0,0.25)",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title value="Chegada e saída de veículos na mina" />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <SubTitle
              style={{
                color: "#A6A6A6",
                fontSize: "1.2rem",
                marginLeft: "3rem",
                marginBottom: "2rem",
                marginTop: 0,
              }}
              value="Filtros"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: "4rem",
                rowGap: "2rem",
              }}
            >

            <RadioGroup
            value={selectTimeNow}
            style={{
                display: "flex",
                flexDirection: "column",
                flex: 1,
                marginLeft: "4rem",
                rowGap: "2rem",
            }} options={["Agora","Select"]} elementOptions={{
                1:useMemo(()=><HorarioSelect 
                dataInicio={dataInicio}
                dataFim={dataFim}
                tempoInicio={tempoInicio}
                tempoFim={tempoFim}
                setDataInicio={setDataInicio}
                setDataFim={setDataFim}
                setTempoInicio={setTempoInicio}
                setTempoFim={setTempoFim}
                />,[dataInicio,dataFim,tempoInicio,tempoFim])
            }} name="time-radio" onChange={(value) => {

                setSelectTimeNow(value as "Agora"|"Select")
            }}/>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "2rem",
          }}
        >
          <div>
            <SubTitle
              value="Controle de entrada de veículos na mina - valores totais"
              style={{
                color: "#A6A6A6",
                marginTop: 0,
                fontSize: "1.2rem",
                marginLeft: "3rem",
                marginBottom: "2rem",
              }}
            />
          </div>
          <div>
            {Object.keys(dataCards).length > 0 && (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4,auto)",
                  rowGap: "2rem",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "0 3rem",
                  flex: 1,
                }}
              >
                {Object.values(dataCards)
                  .map((data, index) => {

                      return (
                        <Card
                          onClick={(e) => {
                          }}
                          id={`${data.nome}-card-${data.quant}-${data.color}`}
                          number={data.quant}
                          title={data.nome}
                          color={data.color}
                          titleColor={data.color}
                        />
                      );
                  })}
              </div>
            )}
          </div>
        </div>
      </Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: 1,
          columnGap: "3rem",
        }}
      >
        <Paper
          style={{
            display: "flex",
            flex: 1,
            maxHeight: "35rem",
            borderRadius: "30px",
            boxShadow: "0.5px 0.5px  6px 0.5px rgba(0,0,0,0.25)",
            flexDirection: "column",
            paddingBottom: "1rem",
          }}
        >
          <Title value={`Detalhamento dos dados`} />

            <SubTitle
              value={`Data: ${getDataStringValueDetalhamento()}`}
              style={{
                color: "#A6A6A6",
                marginTop: 0,
                fontSize: "1.2rem",
                marginLeft: "3rem",
                marginBottom: "2rem",
              }}
              />
          <div style={{ padding: "0 1rem 0 1rem" }}>
            <Table
            style={{
                overflow:"auto",
                overflowY:"hidden"
              }}
              columns={[
                {
                  name: "Status",
                  size: 1.5,
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                },
                { size: 1, name: "Veículo" },
                { name: "Morotista", size: 2 },

                { name: "Passageiros", size: 1 },

                { name: "Entrada", size: 2 },

                { name: "Saída", size: 2 },
              ]}
              rows={rows}
            />
          </div>
        </Paper>
      </div>
    </div>
  );
}
