import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  BodyLocalizacao,
  DadosLocalizacao,
  DataLocalizacao,
} from "../../types";
import {
  deleteLocalizacao as _deleteLocalizacao,
  getLocalizacao as _getLocalizacao,
  postLocalizacao as _postLocalizacao,
} from "./api";
function preprocessing(localizacoes: Array<DadosLocalizacao>) {
  const pontosAux: Array<
    Omit<DadosLocalizacao, "localizacao"> & {
      localizacao: Array<number | [number, number]>;
    }
  > = [];
  localizacoes.forEach((localizacao) => {
    if (localizacao.tipo === "area")
      pontosAux.push({
        descricao: localizacao.descricao,
        nome: localizacao.nome,
        tipo: localizacao.tipo,
        id: localizacao.id,
        localizacao: localizacao.localizacao[0] as Array<number>,
      });
    else
      pontosAux.push({
        descricao: localizacao.descricao,
        nome: localizacao.nome,
        tipo: localizacao.tipo,
        id: localizacao.id,
        localizacao: localizacao.localizacao as unknown as [number, number][],
      });
  });
  return pontosAux;
}
export default function useLocalizacao(params?: {
  query?: string;
  onSuccess?: (localizacoes: Array<DadosLocalizacao>) => void;
}) {
  const queryClient = useQueryClient();
  const {
    mutate: mutateDelete,
    isError: isErrorDelete,
    isLoading: isLoadingDelete,
  } = useMutation(_deleteLocalizacao);
  const {
    mutate,
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
  } = useMutation(_postLocalizacao);
  function deleteLocalizacao(
    id: string | number,
    onSuccess: (data: DataLocalizacao) => void | Promise<void>,
    onError: (e: unknown) => void
  ) {
    mutateDelete(id, {
      onError,
      onSuccess: (data) => {
        onSuccess?.(data);
        queryClient.setQueriesData("localizacao", (old: any) => {
          return old.filter(
            (localizacao: DataLocalizacao) => localizacao.id !== id
          );
        });
      },
    });
  }
  function createLocalizacao(
    body: BodyLocalizacao,
    onSuccess?: () => void,
    onError?: () => void,
    onSettled?: () => void
  ) {
    mutate(body, {
      onSuccess,
      onError,
      onSettled,
    });
    return { isLoadingCreate, isErrorCreate };
  }
  const { data, isLoading, isError } = useQuery(
    "localizacao",
    async () => {
      const data = await _getLocalizacao(params?.query);
      return preprocessing(data);
    },
    {
      onSuccess: params?.onSuccess,
    }
  );
  return { data, isLoading, isError, deleteLocalizacao, createLocalizacao };
}
