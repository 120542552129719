export default function BarraDeNavegacao() {
  return (
    <div
      style={{
        backgroundColor: "white",
        flex: 1,
        display: "flex",
        maxHeight: "2rem",
        minHeight: "2rem",
        marginTop: "0.7rem",
        padding: "1.5rem 1.5rem 1.5rem 4rem",
      }}
    >
      <span>{"Início >"}</span>
    </div>
  );
}
