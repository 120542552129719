import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataUpdateFunction, Updater } from "react-query/types/core/utils";
import { DadosCracha } from "../../types";
import { getCracha, deleteChacha as _deleteCracha } from "./api";

export default function useCracha() {
  const queryClient = useQueryClient();
  const { data, isLoading, isError } = useQuery("crachas", getCracha);
  const deleteCrachaMutation = useMutation(_deleteCracha);
  function deleteCracha(
    id: string,
    onSuccessDelete?: (data: DadosCracha | undefined) => void | Promise<void>,
    onErrorDelete?: () => void | Promise<void>
  ) {
    const { data, isLoading, isError, mutate } = deleteCrachaMutation;
    mutate(id, {
      onSuccess: () => {
        queryClient.setQueriesData("crachas", (old: any) => {
          const result = old.filter((row: DadosCracha) => row.devEUI !== id);
          console.debug(result);
          onSuccessDelete?.(data);
          return result;
        });
      },
      onError: () => {
        onErrorDelete?.();
      },
    });
  }
  return { data, isLoading, isError, deleteCracha };
}
