import { useEffect, useMemo, useRef, useState } from "react";
import MultiSelect from "../../components/multiSelect";
import Paper from "../../components/paper";
import Table from "../../components/table";
import Title from "../../components/title";
import { useGlobalContext } from "../../context/globalContext";
import useFuncionarios from "../../hooks/useQuery/useFuncionarios";
import useLocalizacao from "../../hooks/useQuery/useLocalizacao";
import { DadosFuncionarios } from "../../types";
import {
  formatDateOrDateStringToString,
  formatHourByDateOrDateString,
} from "../../utils/dates.utils";

export default function RelatorioPanico() {
  //const toast = useToast();
  const [rows, setRows] = useState<
    Array<
      [
        string,
        string,
        string,
        string,
        string,
        number,
        number,
        string,
        string,
        string
      ]
    >
  >([]);
  const { panics } = useGlobalContext();

  const [opcoesAreas, setOpcoesAreas] =
    useState<Record<string | number, string | number>>();
  const [areasSelected, setAreaSelect] = useState<
    Record<string | number, string | number>
  >({
    todos: "Todos",
  });

  useLocalizacao({
    query: useMemo(() => {
      return undefined;
    }, []),
    onSuccess: (areas) => {
      const opcoesAreaAux: typeof opcoesAreas = {};

      areas.forEach((area) => {
        opcoesAreaAux[area.id] = area.nome;
      });
      setOpcoesAreas(opcoesAreaAux);
    },
  });
  const [optionsFuncionarios, setOptionsFuncionarios] = useState<
    Record<string | number, string | number>
  >({});
  useFuncionarios({
    onSuccess: (funcionarios) => {
      const optionsAux: typeof optionsFuncionarios = {};
      funcionarios.forEach((funcionario) => {
        optionsAux[funcionario.id] = funcionario.nome;
      });
      setOptionsFuncionarios(optionsAux);
    },
  });
  const [funcionariosSelected, setFuncionariosSelected] = useState<
    Record<string | number, string | number>
  >({ todos: "Todos" });

  useEffect(() => {
    const rowsAux: typeof rows = [];
    const funcs: Record<string, number> = {};
    const areas: Record<string | number, number> = {};

    Object.values(funcionariosSelected).forEach((value) => {
      funcs[value] = 1;
    });
    Object.values(areasSelected).forEach((value) => {
      areas[value] = 1;
    });
    panics.tratados.forEach((tratado) => {
      if (
        (!("todos" in areasSelected) && !(tratado.area.nome in areas)) ||
        (!("todos" in funcionariosSelected) && !(tratado.funcionario in funcs))
      )
        return;
      rowsAux.push([
        formatDateOrDateStringToString(tratado.date),
        formatHourByDateOrDateString(tratado.date),
        tratado.funcionario,
        tratado.telefone,
        tratado.area.nome,

        tratado.localizacao[0],
        tratado.localizacao[1],
        formatDateOrDateStringToString(tratado?.date_confirmacao as string),
        formatHourByDateOrDateString(tratado?.date_confirmacao || ""),
        tratado?.login_confirmacao || "",
      ]);
    });
    setRows(rowsAux);
  }, [funcionariosSelected, areasSelected]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",

        overflowY: "auto",
      }}
    >
      <Paper
        style={{
          flex: 1,
          minHeight: "100vh",
          backgroundColor: "white",
          overflow: "auto",
        }}
      >
        <Title style={{ marginTop: "5rem" }} value="Relatório de pânicos" />
        <div
          style={{
            padding: "0 2rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: "3rem",
          }}
        >
          <div
            style={{
              display: "flex",
              rowGap: "1.5rem",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                rowGap: "1rem",
                flexDirection: "column",
              }}
            >
              <label
                style={{
                  fontSize: "1rem",
                  fontFamily: "inter",
                  fontWeight: 500,
                }}
              >
                Locais:
              </label>
              <MultiSelect
                style={{ minWidth: "100%", maxWidth: "300px", flex: 0 }}
                onSelectAll={() => {
                  setAreaSelect({ todos: "Todos" });
                }}
                onRemoveAll={() => {
                  setAreaSelect({});
                }}
                selected={areasSelected}
                onRemove={(value) => {
                  const areasSelectedCopy = { ...areasSelected };
                  delete areasSelectedCopy[value[0]];
                  setAreaSelect(areasSelectedCopy);
                }}
                onSelect={(value) => {
                  if ("todos" in areasSelected) {
                    setAreaSelect({ [value[0]]: value[1] });
                    return;
                  }
                  const areasSelectedCopy = { ...areasSelected };
                  areasSelectedCopy[value[0]] = value[1];
                  setAreaSelect(areasSelectedCopy);
                }}
                options={opcoesAreas}
              />
            </div>
            <div
              style={{
                display: "flex",
                rowGap: "1rem",
                flexDirection: "column",

                flex: 1,
              }}
            >
              <label
                style={{
                  fontSize: "1rem",
                  fontFamily: "inter",
                  fontWeight: 500,
                }}
              >
                Funcionários:
              </label>
              <MultiSelect
                style={{ width: "100%" }}
                onSelectAll={() => {
                  setFuncionariosSelected({ todos: "Todos" });
                }}
                onRemoveAll={() => {
                  setFuncionariosSelected({});
                }}
                selected={funcionariosSelected}
                onRemove={(value) => {
                  const funcionariosSelectedCopy = { ...funcionariosSelected };
                  delete funcionariosSelectedCopy[value[0]];
                  setFuncionariosSelected(funcionariosSelectedCopy);
                }}
                onSelect={(value) => {
                  if ("todos" in funcionariosSelected) {
                    setFuncionariosSelected({ [value[0]]: value[1] });
                    return;
                  }
                  const funcionariosSelectedCopy = { ...funcionariosSelected };
                  funcionariosSelectedCopy[value[0]] = value[1];
                  setFuncionariosSelected(funcionariosSelectedCopy);
                }}
                options={optionsFuncionarios}
              />
            </div>
          </div>
          <Table
            style={{
              width: 1500,
            }}
            rows={rows}
            resizable
            columns={[
              { name: "Data", size: 1 },
              { name: "Horário", size: 1 },
              { name: "Pessoa", size: 1 },
              { name: "Telefone", size: 1.2 },
              { name: "Área", size: 1.2 },
              {
                name: "Latitude",
                size: 1,
                style: {
                  minWidth: "fit-content",
                },
              },
              {
                name: "Longitude",
                size: 1,
                style: {
                  minWidth: "fit-content",
                },
              },

              { name: "Data", size: 1 },

              { name: "Horário", size: 1 },

              { name: "Login", size: 1 },
            ]}
          />
        </div>
      </Paper>
    </div>
  );
}
