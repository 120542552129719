import * as React from "react";
import { SVGProps } from "react";

const TwoPersons = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 30 23"
    width="28"
    height="31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28.125 22.514s1.875 0 1.875-1.875-1.875-7.5-9.375-7.5-9.375 5.625-9.375 7.5 1.875 1.875 1.875 1.875h15Zm-14.959-1.875a.573.573 0 0 1-.041-.008c.002-.495.313-1.93 1.425-3.225 1.035-1.213 2.854-2.392 6.075-2.392 3.22 0 5.038 1.181 6.075 2.392 1.112 1.294 1.421 2.732 1.425 3.225l-.015.004a.574.574 0 0 1-.026.004H13.166Zm7.459-11.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Zm5.625-3.75a5.625 5.625 0 1 1-11.25 0 5.625 5.625 0 0 1 11.25 0Zm-13.245 8.025a11.024 11.024 0 0 0-2.306-.463c-.44-.042-.882-.063-1.324-.062-7.5 0-9.375 5.625-9.375 7.5 0 1.25.624 1.875 1.875 1.875H9.78a4.197 4.197 0 0 1-.405-1.875c0-1.894.707-3.829 2.044-5.445a9.917 9.917 0 0 1 1.586-1.53Zm-3.78 1.35A10.3 10.3 0 0 0 7.5 20.639H1.875c0-.488.308-1.931 1.425-3.233 1.022-1.192 2.798-2.355 5.925-2.39v-.002ZM2.813 6.576a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0Zm5.624-3.75a3.75 3.75 0 1 0 0 7.5 3.75 3.75 0 0 0 0-7.5Z"
      fill={props.color}
    />
  </svg>
);

export default TwoPersons;
