import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  BodyRelacao,
  DadosRelacao,
  DadosSemRelacao,
  DeleteRelacao,
} from "../../types";
import {
  getSemRelacao,
  getRelacao,
  postRelacao as _postRelacao,
  deleteRelacao as _deleteRelacao,
} from "./api";

export default function useRelacao(params?: {
  onSuccess?: (data: Array<DadosRelacao>) => void;
  onSuccessSemRelacao?: (data: DadosSemRelacao) => void;
  onSuccessPost?: (data: DadosRelacao) => void;
  onErrorPost?: (e: unknown) => void;
  onSuccessDelete?: (index: number) => void;
}) {
  const {
    data: dataSemRelacao,
    isLoading: isLoadingSemRelacao,
    isError: isErrorSemRelacao,
  } = useQuery("semRelacao", getSemRelacao, {
    onSuccess: params?.onSuccessSemRelacao,
  });
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery("relacao", getRelacao, {
    onSuccess: params?.onSuccess,
  });
  const { mutate } = useMutation(_postRelacao);
  const { mutate: mutateDelete } = useMutation(_deleteRelacao);
  //usar a mesma coisas que o delete aqui
  function postRelacao(body: BodyRelacao) {
    mutate(body, {
      onSuccess: params?.onSuccessPost,
      onError: params?.onErrorPost,
    });
  }
  function deleteRelacao(id: number, index?: number) {
    mutateDelete(id, {
      onSuccess: (data: DeleteRelacao) => {
        queryClient.setQueriesData("relacao", (old: any) => {
          const result = old.filter(
            (row: DadosRelacao) => row.funcionarioId !== data.funcionarioId
          );
          params?.onSuccessDelete?.(index as number);
          return result;
        });
      },
    });
  }

  return {
    data: data as Array<DadosRelacao>,
    isLoading,
    isError,
    dataSemRelacao: dataSemRelacao as DadosSemRelacao,
    postRelacao,
    deleteRelacao,
  };
}
