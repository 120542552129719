import React, { useCallback, useState } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles.css";
import Calendario from "../calendario";
import Paper from "../paper";
import Button from "../button";
export default function DatePickerCustom({
  id,
  style,
  label,
  defaultDateValue,
  defaultTimeStartValue,
  defaultTimeEndValue,
  timePicker,
  onChange,
  onConfirm,
  calendarStyleWrapper,
  range = true,
}: {
  id?: string;
  label?: string;
  defaultDateValue?: Date;
  range?: boolean;
  defaultTimeStartValue?: string;
  defaultTimeEndValue?: string;
  timePicker?: boolean;
  calendarStyleWrapper?: React.CSSProperties;
  onChange?: (value: Date) => void;
  style?: React.CSSProperties;
  onConfirm?: ({
    date,
    startTime,
    endTime,
  }: {
    date: Date;
    startTime?: string;
    endTime?: string;
  }) => void;
}) {
  const [isOpenState, setIsOpenState] = useState(false);
  const handleCalendarioOnChange = useCallback((newDate: Date) => {
    setDateValueState(newDate);
    if (onConfirm) {
      return;
    }
    setIsOpenState(false);
    onChange?.(newDate);
  }, []);
  const [dateValueState, setDateValueState] = useState(defaultDateValue);
  const [timeStartValueState, setTimeStartValueState] = useState(
    defaultTimeStartValue || ""
  );
  const [timeEndValueState, setTimeEndValueState] = useState(
    defaultTimeEndValue || ""
  );
  return (
    <div
      style={{
        position: "relative",
        flexDirection: style?.flexDirection || "row",
      }}
    >
      {isOpenState && (
        <div
          onKeyUp={() => {}}
          onClick={() => {
            setIsOpenState(false);
          }}
          style={{
            width: "100vw",
            position: "fixed",
            top: 0,
            right: 0,
            flex: 1,
            height: "100vh",
            zIndex: 4,
            backgroundColor: "transparent",
          }}
        />
      )}

      {label && <span style={{ marginRight: "1rem" }}>{label}</span>}
      <div
        style={{
          border: "1px solid rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
          padding: "0.2rem 2rem",
          borderRadius: "5px",
          backgroundColor: "white",
          ...style,
        }}
        onKeyUp={() => {}}
        onClick={() => setIsOpenState(!isOpenState)}
        id={id}
      >
        {timeStartValueState && timeEndValueState
          ? `${
              defaultDateValue
                ?.toLocaleString("pt-BR")
                .split("T")[0]
                .split(",")[0]
            }, ${defaultTimeStartValue} até ${defaultTimeEndValue}`
          : timeStartValueState
          ? `${
              defaultDateValue
                ?.toLocaleString("pt-BR")
                .split("T")[0]
                .split(",")[0]
            }, ${defaultTimeStartValue}`
          : `${
              defaultDateValue
                ?.toLocaleString("pt-BR")
                .split("T")[0]
                .split(",")[0]
            }`}
      </div>
      {isOpenState && (
        <div>
          <Paper
            onClick={(e) => {
              e.stopPropagation();
              //e.preventDefault();
            }}
            style={{
              zIndex: 7,
              position: "absolute",
              backgroundColor: "rgb(65, 13, 91)",
              padding: timePicker ? "1rem" : "0rem",
              borderRadius: "0.5rem",
              display: "flex",
              flexDirection: "row",
              columnGap: "1rem",
              boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
              marginTop: "0.5rem",

              ...calendarStyleWrapper,
            }}
          >
            <Calendario
              locale="pt-BR"
              onChange={handleCalendarioOnChange}
              value={dateValueState || new Date()}
            />
            {timePicker && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    rowGap: "0.9rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "0.5rem",
                    }}
                  >
                    <label
                      style={{
                        color: "white",
                      }}
                    >
                      De
                    </label>
                    <input
step="3600" min="00:00" max="23:59" pattern="[0-2][0-9]:[0-5][0-9]"
                      type="time"
                      value={timeStartValueState}
                      onChange={(e) => {
                        const value = e.target.value;
                        setTimeStartValueState(value);
                      }}
                      name="de"
                      style={{
                        textAlign: "center",

                        padding: "0.2rem",
                      }}
                      placeholder="9:00"
                    />
                  </div>
                  {range && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.5rem",
                      }}
                    >
                      <label
                        style={{
                          color: "white",
                        }}
                      >
                        Até
                      </label>
                      <input
                        type="time"
                        onChange={(e) => {
                          const value = e.target.value;
                          setTimeEndValueState(value);
                        }}
                        value={timeEndValueState}
                        placeholder="10:00"
                        style={{
                          textAlign: "center",
                          padding: "0.2rem",
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1.5,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    style={{
                      color: "black",
                      backgroundColor: "whitesmoke",
                    }}
                    label="Confirmar"
                    onClick={() => {
                      onConfirm?.({
                        date: dateValueState || new Date(),
                        startTime: timeStartValueState,
                        endTime: timeEndValueState as string,
                      });
                      setIsOpenState(false);
                    }}
                  />
                </div>
              </div>
            )}
          </Paper>
        </div>
      )}
    </div>
  );
}
