import { getDataStatus as _getDataStatus, getData as _getData } from "./api";
import { useMutation } from "react-query";
import { BodyDataStatus, DadosData, DataDataStatusTratada } from "../../types";
import { useEffect, useState } from "react";
export default function useData(params?: {
  areas?: BodyDataStatus["areas"];
  query?: string;
}) {
  const [data, setData] = useState<Array<DataDataStatusTratada>>([]);
  const getDataStatusMutation = useMutation(
    async ({ body, query }: { body: BodyDataStatus; query?: string }) => {
      return (await _getDataStatus(body, query)) || [];
    }
  );
  const { mutate: getDataMutation } = useMutation(_getData);
  const { isError, isLoading, mutate } = getDataStatusMutation;
  function getDataStatus(
    body: { areas: BodyDataStatus["areas"]; query?: string },
    onSuccess?: (data: Array<DataDataStatusTratada>) => void | Promise<void>
  ) {
    mutate(
      {
        body: { areas: body.areas },
        query: body.query,
      },
      {
        onSuccess,
      }
    );
  }
  function getData(
    query?: string,
    onSuccess?: (dados: Array<DadosData>) => void | Promise<void>
  ) {
    getDataMutation(query || "", {
      onSuccess: (data) => {
        onSuccess?.(data);
      },
    });
  }
  useEffect(() => {
    if (!params?.areas) return;
    mutate(
      {
        body: { areas: params.areas as BodyDataStatus["areas"] },
        query: params?.query,
      },
      {
        onSuccess: (newData: Array<DataDataStatusTratada>) => {
          setData(newData);
        },
        onError: (e) => {
          console.log(e);
        },
      }
    );
  }, [params?.areas, params?.query]);

  return {
    isError,
    isLoading,
    data: data as Array<DataDataStatusTratada>,
    getData,
    getDataStatus,
  };
}
