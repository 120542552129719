export default function NotFound() {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        marginTop: "4rem",
      }}
    >
      <h1>404 - Página não existe</h1>
    </div>
  );
}
