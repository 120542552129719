import {
  DadosArea,
  DadosFuncionarios,
  EditFuncionario,
  PostFuncionario,
} from "../../types";
import Modal from "../../components/modal";
import Paper from "../../components/paper";
import CustomInput from "../../components/input";
import { useState } from "react";
import {
  maxPhoneNumber,
  phoneMask,
  phoneMaskVisual,
} from "../../utils/mascaras";
import CustomSelect from "../../components/select";
import useArea from "../../hooks/useQuery/useArea";
import Button from "../../components/button";
import { useToast } from "../../components/toast";
import useFuncionarios from "../../hooks/useQuery/useFuncionarios";
import Title from "../../components/title";
export default function ModalUpdateFuncionario({
  isOpen,
  onClose,
  dados,
  onConfirm,
}: {
  isOpen?: boolean;
  onClose?: () => void;
  dados?: DadosFuncionarios;
  onConfirm?: () => void;
}) {
  const toastCall = useToast().toastCallTopRight as Function;
  const { updateFuncionario } = useFuncionarios();
  const [nome, setNome] = useState<string>(dados?.nome || "");
  const [telefone, setTelefone] = useState<string>(dados?.telefone || "");
  const [matricula, setMatricula] = useState<string>(dados?.matricula || "");
  const [login, setLogin] = useState<string>(dados?.login || "");
  const [senha, setSenha] = useState<string>(dados?.senha || "");
  const [area, setArea] = useState(dados?.area || "");
  const [optionsArea, setOptionsArea] = useState<
    Array<{ value: string | number; label: string }>
  >([]);
  useArea({
    onSuccess: (areas: Array<DadosArea>) => {
      const optionsAreaAux: typeof optionsArea = [];
      areas.forEach((area) => {
        optionsAreaAux.push({ label: area.nome, value: area.nome });
      });
      setOptionsArea(optionsAreaAux);
    },
  });

  return (
    <div>
      <Modal
        style={{ display: "flex", flex: 1 }}
        visibility={isOpen}
        onClickOutside={onClose}
      >
        <Paper
          style={{
            width: "80vw",
            height: "80vh",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            borderRadius: "10px",
            padding: "2rem",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.5)",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Title
            style={{
              alignSelf: "flex-start",
              marginBottom: "4rem",
              marginTop: "4rem",
            }}
            value={`Editando Funcionario ${dados?.nome}`}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2,20rem)",
              gridColumnGap: "10rem",
              padding: "0rem 0rem",
              gridRowGap: "5rem",
            }}
          >
            <div style={{}}>
              <CustomInput
                onChange={(text: string) => {
                  setNome(text);
                }}
                label="Nome"
                placeholder="Ex: José da silva"
                value={nome}
              />
            </div>
            <div style={{}}>
              <CustomInput
                value={matricula}
                onChange={(text) => {
                  setMatricula(text);
                }}
                label="Matrícula"
                placeholder="Ex: 1234532553"
              />
            </div>
            <div style={{}}>
              <CustomInput
                max={maxPhoneNumber(telefone)}
                value={phoneMaskVisual(telefone)}
                label="Telefone"
                onChange={(text) => {
                  setTelefone(phoneMask(text));
                }}
                placeholder="(31) 994356453"
              />
            </div>
            <div style={{}}>
              <CustomInput
                value={login}
                label="Login"
                onChange={(text) => {
                  setLogin(text);
                }}
                placeholder="Ex: joseSilva"
              />
            </div>
            <div style={{}}>
              <CustomInput
                value={senha}
                label="Senha"
                onChange={(text) => {
                  setSenha(text);
                }}
                placeholder="Ex: 12330420BR"
              />
            </div>

            <div style={{}}>
              <CustomSelect
                value={area}
                onChange={(value) => {
                  setArea(value as string);
                }}
                options={optionsArea}
                label="Grupo"
              />
            </div>
          </div>

          <div
            style={{
              padding: "2rem",
              justifyContent: "flex-end",
              display: "flex",
              marginTop: "4rem",
              alignSelf: "flex-end",
              marginRight: "2rem",
            }}
          >
            <Button
              onClick={() => {
                if (
                  nome === "" ||
                  matricula === "" ||
                  telefone === "" ||
                  login === "" ||
                  senha === ""
                ) {
                  toastCall("Existem campos com valores inválidos");
                  return;
                }
                console.log(area);
                const body: EditFuncionario = {
                  nome,
                  telefone,
                  matricula,
                  login,
                  senha,
                  area,
                  id: dados?.id as number,
                };
                toastCall("Realizando edição");
                updateFuncionario(
                  body as EditFuncionario,
                  () => {
                    toastCall("Edição realizada com sucesso");
                    onConfirm?.();
                  },
                  (e: any) => {
                    toastCall(`Erro ao editar funcionario: ${e}`);
                  }
                );
              }}
              label="Editar"
            />
          </div>
        </Paper>
      </Modal>
    </div>
  );
}
