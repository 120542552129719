import * as React from "react";
import { SVGProps } from "react";

const Relatorio = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 30 30"
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.156 7.734a.235.235 0 0 0-.234-.234H8.672a.235.235 0 0 0-.235.234v1.407c0 .129.106.234.235.234h11.25a.235.235 0 0 0 .234-.234V7.734Zm-.234 3.985H8.672a.235.235 0 0 0-.235.234v1.406c0 .13.106.235.235.235h11.25a.235.235 0 0 0 .234-.235v-1.406a.235.235 0 0 0-.234-.234Zm-5.86 4.219h-5.39a.235.235 0 0 0-.235.234v1.406c0 .129.106.235.235.235h5.39a.235.235 0 0 0 .235-.235v-1.406a.235.235 0 0 0-.235-.235Zm-1.406 9.023H6.094V4.336H22.5v10.078c0 .129.105.234.234.234h1.641a.235.235 0 0 0 .234-.234V3.164a.937.937 0 0 0-.937-.937H4.922a.936.936 0 0 0-.938.937v22.969c0 .518.42.937.938.937h7.734a.235.235 0 0 0 .235-.234v-1.64a.235.235 0 0 0-.235-.235Zm10.453-2.18a4.22 4.22 0 1 0-7.055-3.123c0 1.24.534 2.353 1.383 3.123a5.758 5.758 0 0 0-2.906 4.75.235.235 0 0 0 .235.242h1.409a.237.237 0 0 0 .234-.222 3.874 3.874 0 0 1 3.864-3.645c2.06 0 3.75 1.617 3.865 3.645a.235.235 0 0 0 .234.222h1.41c.134 0 .24-.11.234-.243a5.747 5.747 0 0 0-2.907-4.749Zm-2.836-5.466A2.343 2.343 0 1 1 20.275 22a2.343 2.343 0 0 1-.002-4.687Z"
      fill={props.color}
    />
  </svg>
);

export default Relatorio;
