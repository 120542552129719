import { Map, VectorTile } from "ol";
import VectorLayer from "ol/layer/Vector";
import React, {
  LegacyRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import OptionsMenu from "../../components/optionsMenu";
import Paper from "../../components/paper";
import SquareColor from "../../components/squareColor";
import Table from "../../components/table";
import Title from "../../components/title";
import { toastContext } from "../../components/toast";
import useMap, { addVectorLayer, drawPoint } from "../../hooks/useMap";
import useData from "../../hooks/useQuery/useData";
import useLocalizacao from "../../hooks/useQuery/useLocalizacao";
import { DadosLocalizacao, DataDataStatusTratada } from "../../types";
import {
  generateAdjustedTimeAndDateByDay,
  getDateFromISOStringOrDate,
} from "../../utils/dates.utils";
import randomColorGenerator from "../../utils/randomColorGenerator";

type Funcionario = {
  nome: string;
  localizacao: [number, number];
  cor: string;
  data: string;
  layer: VectorLayer<any>;
};

export default function VisualizacaoEmGrupo() {
  const INITIAL_DATE = useMemo(() => generateAdjustedTimeAndDateByDay(), []);
  const [data, setData] = useState(INITIAL_DATE[2]);
  const [de, setDe] = useState(INITIAL_DATE[1]);

  const [area, setArea] = useState<number | string>();
  const [ate, setAte] = useState(INITIAL_DATE[0]);
  const [fullscreen, setFullscreen] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [areaOptions, setAreaOptions] = useState<
    Array<{ label: string; value: string | number }>
  >([]);

  const toastCall = useContext(toastContext).toastCall as Function;
  const lastArea = useRef<undefined | number>();
  const onSuccessLocalizacao = useCallback(
    (data: DadosLocalizacao[]) => {
      const areaOptionsAux: typeof areaOptions = [];
      data?.forEach((areaData) => {
        if (area === null) return;
        if (!(areaData.id in locationsCoordinates.current)) {
          locationsCoordinates.current[areaData.id] = areaData.localizacao;
        }
        areaOptionsAux.push({ value: areaData?.id, label: areaData?.nome });
      });
      setAreaOptions(areaOptionsAux);
      if (area === undefined) setArea(areaOptionsAux[0]?.value as number);
    },
    [setArea, setAreaOptions]
  );
  useLocalizacao({
    query: useMemo(() => "tipo=area", []),
    onSuccess: onSuccessLocalizacao,
  });
  const [rows, setRows] = useState<
    Array<
      [
        {
          funcionario: Funcionario;
        },
        JSX.Element,
        string,
        string
      ]
    >
  >([]);
  const mapRefUltimoPonto = useRef<HTMLDivElement>(null);
  const locationsCoordinates = useRef<Record<string, any>>({});
  const oldColorsLayers = useRef<Array<string>>([]);
  const funcionariosRef = useRef<Array<Funcionario>>([]);
  const [checkboxesCheckState, setCheckboxesCheckState] = useState<
    Array<boolean>
  >([]);
  const { getDataStatus } = useData();
  const mapUltimoPonto = useRef<Map>();
  mapUltimoPonto.current = useMap(
    mapRefUltimoPonto as React.RefObject<HTMLDivElement>
  );
  const checkAllCheckboxes = useCallback((checked: boolean) => {
    setCheckboxesCheckState((prev) => {
      return prev.map((value, index) => {
        if (checked) {
          funcionariosRef.current[index].layer.setVisible(true);
          return true;
        }
        funcionariosRef.current[index].layer.setVisible(false);
        return false;
      });
    });
  }, []);
  const assemblyColorSquare = useCallback(
    (cor: string, onChange: (color: string) => void) => {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SquareColor
            color={cor}
            onChange={onChange}
            style={{
              borderRadius: "4px",
              width: "1.5rem",
              height: "1.5rem",
            }}
          />
        </div>
      );
    },
    []
  );
  const handlerFilterApply = useCallback(
    (dataStatus: Array<DataDataStatusTratada>) => {
      toastCall("Por favor aguarde", 1000);
      removeAllLayers();
      lastArea.current = area as number;

      if (dataStatus.length === 0) {
        toastCall("Não existem dados.");
        return;
      }

      const funcionariosAux: Array<Funcionario> = [];
      const colors: Record<string, any> = {};

      dataStatus?.forEach((data) => {
        const newColor = randomColorGenerator(colors);
        colors[newColor] = 1;

        const coordinates = JSON.parse(data.localizacao).coordinates;
        oldColorsLayers.current.push(`#${newColor}`);
        const layer = addVectorLayer(
          mapUltimoPonto.current as Map,
          `#${newColor}`,
          10,
          data.id
        );
        funcionariosAux.push({
          nome: data.nome_funcionario,
          cor: `#${newColor}`,
          localizacao: coordinates,
          data: data.date,
          layer,
        });
      });
      showDataOnMapAndTable(funcionariosAux);
      funcionariosRef.current = funcionariosAux;
    },
    []
  );
  const removeAllLayers = useCallback(() => {
    funcionariosRef.current.forEach((funcionario) => {
      mapUltimoPonto.current?.removeLayer(funcionario.layer);
    });

    oldColorsLayers.current = [];
  }, []);
  const updateColorsLayers = useCallback(
    (funcionario: Funcionario, newColor: string) => {
      mapUltimoPonto.current?.removeLayer(funcionario.layer);
      const newLayer = addVectorLayer(mapUltimoPonto.current as Map, newColor);
      drawPoint(funcionario.localizacao, newLayer);
      return newLayer;
    },
    []
  );
  const updateColorFuncionario = useCallback(
    (rowIndex: number, funcionario: Funcionario, newColor: string) => {
      setRows((last) => {
        funcionariosRef.current[rowIndex].cor = newColor;
        funcionariosRef.current[rowIndex].layer = updateColorsLayers(
          funcionario,
          newColor
        );
        const rowsCopy = [...last];

        rowsCopy[rowIndex][1] = assemblyColorSquare(newColor, (newNewColor) => {
          console.debug("newNewColor", newNewColor);
          updateColorFuncionario(rowIndex, funcionario, newNewColor);
        });
        return rowsCopy;
      });
    },
    [setRows]
  );
  const changeFunctionarioLayerVisibility = useCallback(
    (funcionario: Funcionario, checked: boolean) => {
      if (checked) {
        funcionario.layer.setVisible(true);
        return;
      }
      funcionario.layer.setVisible(false);
    },
    []
  );
  const showDataOnMapAndTable = useCallback(
    (funcionarios: Array<Funcionario>) => {
      const rowsAux: typeof rows = [];
      console.debug("limpando checkboxes");

      const newCheckBoxesCheckState: typeof checkboxesCheckState = [];
      funcionarios.forEach((funcionario, index) => {
        newCheckBoxesCheckState.push(true);
        rowsAux.push([
          {
            funcionario,
          },
          assemblyColorSquare(funcionario.cor, (newColor) => {
            updateColorFuncionario(index, funcionario, newColor);
          }),

          funcionario.nome,
          new Date(funcionario.data)?.toTimeString()?.split(" ")[0],
        ]);
        drawPoint(funcionario.localizacao, funcionario.layer);
      });

      if (funcionarios.length > 0) {
        mapUltimoPonto.current?.getView().animate({
          center: funcionarios[0].localizacao,
          zoom: 18,
        });
      }
      if (area) {
        mapUltimoPonto.current?.getView().animate({
          center: locationsCoordinates.current[area][0],
          zoom: 15,
        });
      }
      setCheckboxesCheckState(newCheckBoxesCheckState);
      setRows(rowsAux);
    },
    [data, de, ate]
  );
  const generateCompleteRows = useCallback(() => {
    return rows.map((row, index) => {
      return [
        <Checkbox
          checked={checkboxesCheckState[index]}
          style={{ zIndex: 9999999 }}
          onClick={(e) => {
            changeFunctionarioLayerVisibility(
              row[0].funcionario,
              e.currentTarget.checked
            );
            const checkboxesCheckStateCopy = [...checkboxesCheckState];
            checkboxesCheckStateCopy[index] = e.currentTarget.checked;
            setCheckboxesCheckState(checkboxesCheckStateCopy);
          }}
        />,
        row[1],
        row[2],
        row[3],
      ];
    });
  }, [rows, checkboxesCheckState]);
  const stylesFullscreen: React.CSSProperties = {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    overflow: "scroll",
    top: 0,
    left: 0,
    zIndex: 999999,
    backgroundColor: "white",
    display: "flex",
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      {fullscreen && (
        <AiOutlineFullscreenExit
          onClick={() => {
            setFullscreen(false);
          }}
          size={40}
          style={{
            position: "fixed",
            color: "red",
            zIndex: 9999999999,
            top: 10,
            right: 10,
            boxSizing: "border-box",
            padding: 0,
            cursor: "pointer",
          }}
        />
      )}
      <div
        style={
          fullscreen
            ? {
                position: "fixed",

                top: 60,
                zIndex: 999999999,
                left: "50%",
                display: "none",
                flexDirection: "column",
                transform: "translate(-50%,0%)",
                flex: 1,
                width: "80%",
              }
            : {
                marginTop: "-2rem",
                marginBottom: "1rem",
              }
        }
      >
        <OptionsMenu
          style={{
            justifyContent: "left",
          }}
          options={[
            {
              type: "selection",
              value: area,
              onChange: (value) => {
                setArea(value.toString());
              },
              name: "Área:",
              ops: areaOptions,
            },
            {
              type: "datetime",
              onChange: (value) => {
                setDe(value.startTime);
                setAte(value.endTime);
                setData(getDateFromISOStringOrDate(value.date));
              },
              value: new Date(`${data}T12:00`),
              time: {
                startTime: de,
                endTime: ate,
              },
              name: "Data:",
            },
          ]}
        />
        <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              getDataStatus(
                {
                  areas: [area as number | string],
                  query: `de=${data}T${de}&ate=${data}T${ate}`,
                },
                (dataStatus) => {
                  handlerFilterApply(dataStatus);
                }
              );
            }}
            label="Aplicar filtros"
            style={{ alignSelf: "flex-end", marginTop: "1rem" }}
          />
        </div>
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40rem auto",
          gridColumnGap: "2rem",
          gridRowGap: "2rem",
        }}
      >
        <Paper
          style={{
            height: "40rem",
            padding: "0 2rem 2rem 2rem",
            textAlign: "center",
            borderRadius: "10px",
            boxShadow: "1px 1px 8px rgba(0,0,0,.25)",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <AiOutlineFullscreen
            size={30}
            style={{
              position: "absolute",
              top: 20,
              right: 10,
              zIndex: 4,
              cursor: "pointer",
              color: "rgb(65, 13, 91)",
            }}
            onClick={() => {
              setFullscreen(true);
            }}
          />
          <Title value="Último ponto registrado" />
          <div
            style={
              fullscreen ? stylesFullscreen : { flex: 1, position: "relative" }
            }
            ref={mapRefUltimoPonto as LegacyRef<HTMLDivElement>}
          />
        </Paper>
        <Paper style={{ height: "fit-content" }}>
          <Table
            quantPerPage={9}
            rows={generateCompleteRows()}
            columns={[
              {
                key: "checkboxes",
                name: (
                  <Checkbox
                    checked={checkAll}
                    onClick={(e) => {
                      checkAllCheckboxes(e.currentTarget.checked);
                      setCheckAll(e.currentTarget.checked);
                      ("");
                    }}
                    ref={(ref) => {
                      if (ref) ref.checked = true;
                    }}
                  />
                ),
                size: 0.4,
                sort: false,
              },
              {
                name: "Cor",
                size: 0.5,
                sort: false,
              },
              { name: "Funcionário", size: 1 },
              { name: "Horário", size: 1 },
            ]}
          />
        </Paper>
      </div>
    </div>
  );
}
