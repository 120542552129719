import * as React from "react"
import { SVGProps } from "react"
const MountainIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 168 92"
    width={30}
    height={30}
    fill="none"
    {...props}
  >
    <path
      fill={props.color || "#fff"}
      d="M84.667 18.8 70.533 37.6l3.734 4.933c2 2.667 6.8 8.934 10.666 14C88.8 61.467 92 66.133 92 66.667 92 67.733 81.467 76 80.133 76c-.533 0-8.266-10.267-17.466-22.667-9.2-12.4-17.067-22.4-17.6-22.266C44.133 31.333 0 90.267 0 91.333c0 .4 37.867.667 84.133.667 46.267 0 83.867-.533 83.6-1.067-.933-2.4-67.066-90.8-68-90.933C99.2 0 92.4 8.533 84.667 18.8Z"
    />
  </svg>
)
export default MountainIcon
