import { useMutation } from "react-query";
import { DadosFeatureGeoserver, DadosLocalizacao } from "../../types";
import { genericGet } from "./api";

export function useGeoserver() {
  const { mutate } = useMutation(
    genericGet<{
      features: Array<{
        properties: DadosFeatureGeoserver;
      }>;
    }>
  );
  function getFeatures(
    url: string,
    onSuccess: (data: {
      features: Array<{
        properties: DadosFeatureGeoserver;
      }>;
    }) => void | Promise<void>
  ) {
    mutate(url, {
      onSuccess,
    });
  }
  return { getFeatures };
}
