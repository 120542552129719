import React, { ComponentPropsWithRef, forwardRef } from "react";

const Checkbox: React.FC<ComponentPropsWithRef<"input">> = forwardRef(
  ({ style, checked, onClick, disabled }, ref) => {
    return (
      <input
        type="checkbox"
        disabled={disabled}
        style={style}
        checked={checked}
        onClick={onClick}
        ref={ref}
      />
    );
  }
);

export default Checkbox;
