import { memo, useCallback, useContext, useEffect, useState } from "react";
import Button from "../../components/button";
import CustomInput from "../../components/input";
import Paper from "../../components/paper";
import CustomSelect from "../../components/select";
import Title from "../../components/title";
import useFuncionarios from "../../hooks/useQuery/useFuncionarios";
import {
  phoneMask,
  phoneMaskVisual,
  maxPhoneNumber,
} from "../../utils/mascaras";
import { useToast } from "../../components/toast";
import { PostFuncionario } from "../../types";
import useArea from "../../hooks/useQuery/useArea";
const regexDevEUI = new RegExp(/[0-9a-fA-F]+$/);

interface DadosArea {
  nome: string;
}

function CadastroDePessoas() {
  const toastCall = useToast().toastCallTopRight as Function;
  const [nome, setNome] = useState<string>("");
  const [telefone, setTelefone] = useState<string>("");
  const [matricula, setMatricula] = useState<string>("");
  const [login, setLogin] = useState<string>("");
  const [senha, setSenha] = useState<string>("");

  const [devEUI, setDevEUI] = useState<string>("");
  const [nomeCracha, setNomeCracha] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [modelo, setModelo] = useState<string>("");
  //const [deviceProfileName, setDeviceProfileName] = useState<string>("");
  //const [deviceProfileNameOptions, setDeviceProfileNameOptions] = useState<
  // Array<{ value: string; label: string }>
  //>([]);
  const clearCadastroFuncionario = useCallback(() => {
    setNome("");
    setTelefone("");
    setMatricula("");
    setLogin("");
    setSenha("");
  }, []);
  const [optionsArea, setOptionsArea] = useState<
    Array<{ value: string | number; label: string }>
  >([]);
  const { postFuncionario } = useFuncionarios();
  useArea({
    onSuccess: (areas: Array<DadosArea>) => {
      const optionsAreaAux: typeof optionsArea = [];
      areas.forEach((area) => {
        optionsAreaAux.push({ label: area.nome, value: area.nome });
      });
      setOptionsArea(optionsAreaAux);
      setArea(optionsAreaAux[0].value as string);
    },
  });
  const [area, setArea] = useState("");
  return (
    <>
      <Paper
        style={{
          display: "flex",
          padding: "1rem 0rem 3rem 0rem",
          flexDirection: "column",
          boxShadow: "0.5px 0.5px 1px rgba(0,0,0,0.05)",
        }}
      >
        <div
          style={{
            marginBottom: "3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title value="Cadastros &nbsp;>" />
          <h2
            style={{
              fontWeight: 400,
              margin: "0.22rem 0 0 0",
            }}
          >
            &nbsp; Funcionários
          </h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,30rem)",
            gridColumnGap: "5rem",
            padding: "0 5rem",
            gridRowGap: "2rem",
          }}
        >
          <div style={{}}>
            <CustomInput
              onChange={(text: string) => {
                setNome(text);
              }}
              label="Nome"
              placeholder="Ex: José da silva"
              value={nome}
            />
          </div>
          <div style={{}}>
            <CustomInput
              value={matricula}
              onChange={(text) => {
                setMatricula(text);
              }}
              label="Matrícula"
              placeholder="Ex: 1234532553"
            />
          </div>
          <div style={{}}>
            <CustomInput
              max={maxPhoneNumber(telefone)}
              value={phoneMaskVisual(telefone)}
              label="Telefone"
              onChange={(text) => {
                setTelefone(phoneMask(text));
              }}
              placeholder="(31) 994356453"
            />
          </div>
          <div style={{}}>
            <CustomInput
              value={login}
              label="Login"
              onChange={(text) => {
                setLogin(text);
              }}
              placeholder="Ex: joseSilva"
            />
          </div>
          <div style={{}}>
            <CustomInput
              value={senha}
              label="Senha"
              onChange={(text) => {
                setSenha(text);
              }}
              placeholder="Ex: 12330420BR"
            />
          </div>

          <div style={{}}>
            <CustomSelect
              value={area}
              onChange={(value) => {
                setArea(value as string);
              }}
              options={optionsArea}
              label="Grupo"
            />
          </div>
        </div>
        <div
          style={{
            padding: "2rem",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "4rem",
          }}
        >
          <Button
            onClick={() => {
              if (
                nome === "" ||
                matricula === "" ||
                telefone === "" ||
                login === "" ||
                senha === ""
              ) {
                toastCall("Existem campos com valores inválidos");
                return;
              }
              console.log(area);
              const body = { nome, telefone, matricula, login, senha, area };
              toastCall("Realizando cadastro");
              postFuncionario(
                body as PostFuncionario,
                () => {
                  toastCall("Cadastro realizado com sucesso");
                  clearCadastroFuncionario();
                },
                () => {
                  toastCall("Erro ao inserir funcionario");
                }
              );
            }}
            label="Cadastrar"
          />
        </div>
      </Paper>
      <Paper
        style={{
          marginTop: "2rem",
          display: "flex",
          padding: "1rem 0rem 3rem 0rem",
          flexDirection: "column",
          boxShadow: "0.5px 0.5px 1px rgba(0,0,0,0.05)",
        }}
      >
        <div
          style={{
            marginBottom: "3rem",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Title value="Cadastros &nbsp;>" />
          <h2
            style={{
              fontWeight: 400,
              margin: "0.22rem 0 0 0",
            }}
          >
            &nbsp; Crachás
          </h2>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2,30rem)",
            gridColumnGap: "5rem",
            padding: "0 5rem",
            gridRowGap: "2rem",
          }}
        >
          <div style={{}}>
            <CustomInput
              max={16}
              onChange={(text) => {
                console.info(regexDevEUI.test(text), text, devEUI);
                if (regexDevEUI.test(text) || text === "") {
                  setDevEUI(text);
                }
              }}
              value={devEUI}
              label="DevEUI"
              placeholder="Ex: 6e61d507c6284b80"
            />
          </div>
          <div style={{}}>
            <CustomInput
              onChange={(text) => {
                setNomeCracha(text);
              }}
              label="Nome"
              placeholder="Ex: cracha8-ABP"
            />
          </div>
          <div style={{}}>
            <CustomInput
              onChange={(text) => {
                setDescricao(text);
              }}
              value={descricao}
              label="Descrição"
              placeholder="Ex: Beitian 220"
            />
          </div>
          {
            //<div>
            // <CustomSelect
            //   onChange={(text) => {
            //     console.debug("no onChange", text);
            //     setDeviceProfileName(text);
            //   }}
            //   value={deviceProfileName}
            //   options={deviceProfileNameOptions}
            //   label="Devide profile name"
            //   placeholder="Ex: idals-device-ABP"
            // />
            // </div>
          }
          <div style={{}}>
            <CustomInput
              value={modelo}
              onChange={(text) => {
                setModelo(text);
              }}
              label="Modelo"
              placeholder="Ex: CubeCell"
            />
          </div>
        </div>
        <div
          style={{
            padding: "2rem",
            justifyContent: "flex-end",
            display: "flex",
            marginTop: "4rem",
          }}
        >
          <Button
            onClick={() => {
              if (
                devEUI === "" ||
                nomeCracha === "" ||
                descricao === "" ||
                //deviceProfileName === "" ||
                modelo === ""
              ) {
                toastCall("Alguns campos estão vazios");
                return;
              }
              console.info("size", devEUI.length);
              if (!regexDevEUI.test(devEUI) || devEUI.length < 16) {
                toastCall(
                  "O campo DevEUI deve ser um hexadecimal de 16 dígitos"
                );
                return;
              }
              const data = JSON.stringify({
                devEUI,
                nome: nomeCracha,
                description: descricao,
                //deviceProfileName,
                modelo,
              });
              fetch(`${process.env.REACT_APP_API_BASE_URL}/cracha`, {
                method: "POST",
                body: data,
                headers: { "content-type": "application/json" },
              })
                .then((response) => {
                  if (response.status === 201) {
                    toastCall("Crachá cadastrado com sucesso");
                    return;
                  }
                  if (response.status === 409) {
                    toastCall("Já existe um crachá com esse id");
                    return;
                  }

                  if (response.status === 400) {
                    console.debug("status", response.status);
                    response.json().then((value) => {
                      console.log(value.message);
                      toastCall(`Erro:
                      ${value.message}`);
                    });
                    return;
                  }
                  toastCall("Erro, por favor tente mais tarde");
                })
                .then(() => {
                  setDevEUI("");
                  setNomeCracha("");
                  setDescricao("");
                  //setDeviceProfileName(deviceProfileNameOptions[0].value);
                  setModelo("");
                })
                .catch((e) => {
                  console.error("err", e);
                });
            }}
            label="Cadastrar"
          />
        </div>
      </Paper>
    </>
  );
}

export default memo(CadastroDePessoas);
