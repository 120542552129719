import { DadosFuncionarios } from "../types";

function sortByNameAsc(a: { nome: string }, b: { nome: string }) {
  return a.nome.localeCompare(b.nome);
}
export function sortFuncionarios(funcionarios: Array<DadosFuncionarios>) {
  const biggerThanId: Array<(typeof funcionarios)[0]> = [];
  const lessThanId: Array<(typeof funcionarios)[0]> = [];
  funcionarios.forEach((funcionario) => {
    if (funcionario.id >= 86) {
      biggerThanId.push(funcionario);
      return;
    }
    lessThanId.push(funcionario);
  });
  return [
    ...biggerThanId.sort(sortByNameAsc),
    ...lessThanId.sort(sortByNameAsc),
  ];
}
