import React, { useCallback, useMemo } from "react";
import { useEffect, useRef, useState } from "react";
import DatePicker from "../../components/datePicker";
import MultiSelect from "../../components/multiSelect";
import Paper from "../../components/paper";
import Table from "../../components/table";
import Title from "../../components/title";
import { useToast } from "../../components/toast";
import useLocalizacao from "../../hooks/useQuery/useLocalizacao";
import useRelatorio from "../../hooks/useQuery/useRelatorio";
import { BodyRelatorio, DadosFuncionarios } from "../../types";
import {
  formatDateOrDateStringToStringWithTime,
  generateAdjustedTimeAndDateByDay,
  getDateFromISOStringOrDate,
} from "../../utils/dates.utils";

export default function RelatorioLocal() {
  const [areasSelected, setAreaSelect] = useState<
    Record<string | number, string | number>
  >({ todos: "Todos" });

  const [opcoesAreas, setOpcoesAreas] = useState<
    Record<string | number, string | number>
  >({});
  useLocalizacao({
    query: useMemo(() => {
      return undefined;
    }, []),
    onSuccess: (areas) => {
      const opcoesAreaAux: typeof opcoesAreas = {};

      areas.forEach((area) => {
        opcoesAreaAux[area.id] = area.nome;
      });
      setOpcoesAreas(opcoesAreaAux);
    },
  });

  const toast = useToast();
  const INITIAL_DATE = useMemo(() => generateAdjustedTimeAndDateByDay(), []);
  const [rows, setRows] = useState<Array<[string, string, string, string]>>([]);
  const [data, setData] = useState(INITIAL_DATE[2]);
  const [de, setDe] = useState(INITIAL_DATE[1]);
  const [ate, setAte] = useState(INITIAL_DATE[0]);
  const query = useMemo(() => {
    return `de=${data}T${de}&ate=${data}T${ate}`;
  }, [data, de, ate]);
  const filter: BodyRelatorio = useMemo(() => {
    console.log("areas mudaram", areasSelected);
    if ("todos" in areasSelected) {
      return {
        funcs: [],
        areas: [],
      };
    }
    const areasID = Object.keys(areasSelected).map((key) => {
      return key;
    });

    return {
      funcs: [],
      areas: areasID,
    };
  }, [areasSelected]);
  useRelatorio({
    query,
    body: filter,
    onSuccess: (dataRelatorios) => {
      const rowsAux: typeof rows = [];
      Object.entries(dataRelatorios.areas).forEach(([areaName, dataAreas]) => {
        dataAreas.forEach((dataArea) => {
          rowsAux.push([
            areaName,
            dataArea.funcionario,
            formatDateOrDateStringToStringWithTime(dataArea.de),
            formatDateOrDateStringToStringWithTime(dataArea.ate),
          ]);
        });
      });
      setRows(rowsAux);
    },
  });

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        columnGap: "3rem",
        rowGap: "3rem",
        paddingLeft: "1rem",
      }}
    >
      <Paper
        style={{
          flex: 1,
          minHeight: "100vh",
          backgroundColor: "white",
          paddingBottom: "2rem",
          overflow: "visible",
        }}
      >
        <Title style={{ marginTop: "5rem" }} value="Relatório de locais" />
        <div
          style={{
            padding: "0 2rem",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              rowGap: "1.5rem",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                rowGap: "1.5rem",
                flexDirection: "column",
                maxWidth: "fit-content",
              }}
            >
              <label>
                <span
                  style={{
                    fontSize: "1rem",
                    fontFamily: "inter",
                    fontWeight: 500,
                  }}
                >
                  Data
                </span>
              </label>
              <DatePicker
                defaultDateValue={new Date(`${data}T12:00`)}
                defaultTimeStartValue={de}
                defaultTimeEndValue={ate}
                onConfirm={(value) => {
                  setData(getDateFromISOStringOrDate(value.date));
                  setDe(value?.startTime as string);
                  setAte(value?.endTime as string);
                }}
                timePicker
              />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                rowGap: "1rem",
              }}
            >
              <label
                style={{
                  fontSize: "1rem",
                  fontFamily: "inter",
                  fontWeight: 500,
                }}
              >
                Locais:
              </label>
              <MultiSelect
                onSelectAll={() => {
                  setAreaSelect({ todos: "Todos" });
                }}
                onRemoveAll={() => {
                  setAreaSelect({});
                }}
                selected={areasSelected}
                onRemove={(value: [string | number, string | number]) => {
                  const areasSelectedCopy = { ...areasSelected };
                  console.debug("value", value);
                  const keyToRemove = value[0];
                  const { [keyToRemove]: _, ...rest } = areasSelectedCopy;
                  setAreaSelect(rest);
                }}
                onSelect={(value) => {
                  if ("todos" in areasSelected) {
                    setAreaSelect({ [value[0]]: value[1] });
                    return;
                  }
                  const areasSelectedCopy = { ...areasSelected };
                  areasSelectedCopy[value[0]] = value[1];
                  setAreaSelect(areasSelectedCopy);
                }}
                options={opcoesAreas}
              />
            </div>
          </div>
          <Table
            rows={rows}
            columns={[
              { name: "Nome da área", size: 1 },
              { name: "Funcionário", size: 1 },
              { name: "De", size: 1 },
              { name: "Até", size: 1 },
            ]}
          />
        </div>
      </Paper>
    </div>
  );
}
