import { useCallback } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Value } from "react-calendar/dist/cjs/shared/types";
import "./styles.css";
export default function Calendario(props: {
  locale?: string;
  value: Date;
  onChange?: (newDate: Date) => void;
}) {
  const handlerOnChange = useCallback(
    (value: Value, event: React.MouseEvent<HTMLButtonElement>) => {
      props.onChange?.(value as Date);
    },
    [props.onChange]
  );
  props.value.setHours(12);
  return <Calendar {...props} onChange={handlerOnChange} />;
}
