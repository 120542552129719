import { useQuery } from "react-query";
import { DadosArea } from "../../types";
import { getAreas } from "./api";

export default function useArea({
  onSuccess,
}: {
  onSuccess: (data: Array<DadosArea>) => void | Promise<void>;
}) {
  const { data, isLoading, isError } = useQuery("areas", getAreas, {
    onSuccess,
  });
  return { data, isLoading, isError };
}
