export function todayInStringFormat() {
  return new Date().toISOString().split("T")[0];
}

export function getDateFromISOStringOrDate(date?: string | Date | null) {
  if (!date) return "";
  return new Date(date).toISOString().split("T")[0];
}

export function timeInputValidation(time: string, newTime: string) {
  let pos: number | null = null;
  let i = 0;
  if (newTime.length > 5) return false; // Allow for ':' hence length is 5
  if (newTime.length < time.length) {
    return true;
  }

  while (time[i] === newTime[i]) {
    pos = i;
    i++;
  }
  const REGEX_ALLOW_UNTIL_NINE = /^[0-9]/;
  const REGEX_ALLOW_UNTIL_FOUR = /^[0-4]/;
  const REGEX_ALLOW_UNTIL_FIVE = /^[0-5]/;
  const REGEX_ALLOW_UNTIL_TWO = /^[0-2]/;
  const REGEX_ALLOW_UNTIL_ONE = /^[0-1]/;
  const newCharacter = newTime[i];
  if (time.length > 1 && pos === 0 && +time[1] >= 4) {
    return REGEX_ALLOW_UNTIL_ONE.test(newCharacter);
  }

  console.debug("time is empty1");
  if (time.length > 1 && pos === 1 && +time[0] === 2) {
    return REGEX_ALLOW_UNTIL_FOUR.test(newCharacter);
  }

  console.debug("time is empty2", time.length, time, newTime, newCharacter);
  if (time.length === 0) {
    console.debug("time is empty3");
    return REGEX_ALLOW_UNTIL_TWO.test(newCharacter);
  }
  if (time.length === 1) {
    if (time[0] === "0" || time[0] === "1") {
      return REGEX_ALLOW_UNTIL_NINE.test(newCharacter);
    }
    return REGEX_ALLOW_UNTIL_FOUR.test(newCharacter);
  }

  if (time.length === 3 && pos === 3) {
    return REGEX_ALLOW_UNTIL_FIVE.test(newCharacter);
  }
  if (time.length === 4 && pos === 4) {
    return REGEX_ALLOW_UNTIL_NINE.test(newCharacter);
  }
  return false;
}
/**
 * description
 * Gera o array de data atual e horas atual e com delay de 1 hora de acordo com UTC-3.
 * retorno [até,de,data]
 */
export function generateAdjustedTimeAndDate() {
  const date = new Date();
  const time: Array<string> = [];
  date.setHours(date.getHours() - 3);
  time.push(date.toISOString().split("T")[1].split(".")[0].slice(0, -3));
  if (time[0].slice(0, 2) === "00") time[0] = "23:59";
  else date.setHours(date.getHours() - 1);
  time.push(date.toISOString().split("T")[1].split(".")[0].slice(0, -3));
  time.push(date.toISOString().split("T")[0]);
  console.log(time);
  return time;
}

export function generateAdjustedTimeAndDateByDay() {
  const date = new Date();
  const time: Array<string> = [];
  date.setHours(date.getHours() - 3);
  time.push("23:59");

  time.push("00:00");
  time.push(date.toISOString().split("T")[0]);
  return time;
}

export function formatDateOrDateStringToString(
  date: string | Date | null
): string {
  if (!date) return "";
  return new Date(date).toLocaleString("pt-BR").split(",")[0];
}

export function formatHourByDateOrDateString(date: string | Date | null) {
  if (!date) return "";
  return new Date(date).toLocaleTimeString("pt-BR");
}

export function formatDateOrDateStringToStringWithTime(
  date: string | Date | null
): string {
  if (!date) return "";
  return new Date(date).toLocaleString("pt-BR");
}

export function createDateFromTimeStringAndDateString(
  date: string,
  time: string
) {
  return new Date(`${date}T${time}`);
}
