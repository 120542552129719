import React, { useEffect, useState } from "react";
import TileLayer from "ol/layer/Tile";
import TileSource from "ol/source/TileWMS";
import TileSourceXYZ from "ol/source/XYZ";
import { register } from "ol/proj/proj4";
import * as ol from "ol";
import proj4 from "proj4";
import { getFeatures } from "../useQuery/api";
import { Circle, Fill } from "ol/style";
import { defaults } from "ol/interaction/defaults";
import { useGeographic as geographic, useGeographic } from "ol/proj";
import { Point } from "ol/geom";
import { Coordinate } from "ol/coordinate";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import { useToast } from "../../components/toast";
export default function useMap(
  mapRef: React.RefObject<HTMLDivElement>,
  overlayElement: HTMLElement | null = null
) {
  const [map, setMap] = useState<ol.Map>();
  const [layers, setLayers] = useState<Record<string, TileLayer<any>>>();
  const toast = useToast();
  useGeographic();
  useEffect(() => {
    const olViewports = mapRef.current?.getElementsByClassName("ol-viewport");
    if (olViewports && olViewports.length > 0) return;
    geographic();
    const bounds = [-43.5118522644043, -20.1697692871094, -43.4932403564453, -20.1590480804443];
    proj4.defs(
      "EPSG:4674",
      "+proj=longlat +ellps=GRS80 +no_defs +type=crs"
    );
    register(proj4);

    const mapLayerSource = new TileSourceXYZ({
      url: "http://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}",
      maxZoom: 20,
      minZoom: 4,
    });
    const tileLayerMap = new TileLayer({ source: mapLayerSource });

    const view = new ol.View({
      maxZoom: 20,
      zoom: 4,
      projection: "EPSG:4674",
      minZoom: 4,
    });
    const mapGenerate = new ol.Map({
      controls: [],
      target: undefined,
      view: view,
      layers: [tileLayerMap],
      keyboardEventTarget: "false",
      interactions: defaults({ keyboard: false }),
    });

    mapGenerate.setTarget(mapRef.current as HTMLDivElement);
    const mapView = mapGenerate.getView();
    mapView.fit(bounds, mapGenerate.getSize() as any);
    const overlay = new ol.Overlay({
      element: overlayElement || undefined,
    });
    mapGenerate.addOverlay(overlay);
    setMap(mapGenerate);
  }, [mapRef]);

  return map;
}
const formatarFiltros = (
  options: Record<string, string | number | null>
): string => {
  let filtroFinal = "";
  for (const key in options) {
    if (options[key] !== null) filtroFinal += `${key}:${options[key]};`;
  }
  return filtroFinal.slice(0, -1);
};
export function updateWMS({
  env,
  options,
  layer,
  onGetFeaturesNumber,
  style,
  shouldUpdateOnZoom = true,
}: {
  layer: TileLayer<TileSource>;
  env?: Record<string, string>;
  options: Record<string, string | number | null>;
  onGetFeaturesNumber?: (quant: number) => void;
  style?: string;
  shouldUpdateOnZoom?: boolean;
}) {
  const tileSource = layer.getSource();
  if (Object.keys(options).length === 0) {
    tileSource?.updateParams({ STYLES: style });
    return;
  }
  const filtro = formatarFiltros(options);
  if (onGetFeaturesNumber)
    getFeatures(filtro).then((data) => {
      onGetFeaturesNumber?.(data.numberReturned);
    });
  if (env) {
    tileSource?.updateParams({
      env: formatarFiltros(env),
      viewparams: filtro,
    });
    return;
  }

  tileSource?.updateParams({
    shouldUpdateOnZoom,
    viewparams: filtro,
    STYLES: style,
  });

  layer?.set("shouldUpdateOnZoom", shouldUpdateOnZoom);
  return;
}
export function drawWMS({
  layer,
  env = {},
  layerName,
  options,
  url,
  onGetFeaturesNumber,
  onError,
  onLoading,
  initStyle,
}: {
  layer: TileLayer<TileSource>;
  options: Record<string, string | number | null>;
  env?: Record<string, string>;
  url: string;
  layerName?: string;
  initStyle?: string;
  onLoading?: (isLoading: boolean) => void;
  onGetFeaturesNumber?: (quant: number) => void;
  onError?: (error?: string) => void;
}) {
  options["limit"] = Number.MAX_SAFE_INTEGER;
  const filtro = formatarFiltros(options);
  getFeatures(filtro).then((data: any) => {
    console.info(data);
    onGetFeaturesNumber?.(data.numberReturned);
  });
  const tileSource = new TileSource({
    url: url,

    params: {
      FORMAT: "image/png",
      VERSION: "1.1.1",
      viewparams: filtro,
      TILED: false,
      env: formatarFiltros(env),
      STYLES: initStyle,
      LAYERS: layerName,
      exceptions: "application/json",
    },
  });
  tileSource.on("tileloadstart", () => {
    onLoading?.(true);
  });
  tileSource.on("tileloaderror", (e) => {
    console.debug("error", e);
    onError?.();
  });
  tileSource.on("tileloadend", () => {
    onLoading?.(false);
  });
  layer.setSource(tileSource);
}
export const addWMSLayer = (
  map: ol.Map,

  initStyle?: string,
  shouldUpdateOnZoom = false
) => {
  const tileSource = new TileSource({
    params: {
      FORMAT: "image/png",
      VERSION: "1.3.0",
      TILED: false,
      STYLES: initStyle,
      exceptions: "application/vnd.ogc.se_inimage",
      tilesOrigin: -56.55622135530706 + "," + -31.21237,
    },
  });
  const tileLayer = new TileLayer({
    source: tileSource,
    visible: true,
    useInterimTilesOnError: true,
  });
  tileLayer.set("shouldUpdateOnZoom", true);
  map.addLayer(tileLayer);
  if (!shouldUpdateOnZoom) return tileLayer;
  let bouncing_timeout: null | number = null;
  map.getView().on("change:resolution", (e) => {
    if (bouncing_timeout) {
      return;
    }
    bouncing_timeout = setTimeout(() => {
      const zoom = map?.getView()?.getZoom();

      const shouldUpdateOnZoomParam = tileLayer?.get("shouldUpdateOnZoom");

      const styleName = tileLayer.getSource()?.getParams()["STYLES"];
      console.info(shouldUpdateOnZoomParam, styleName, zoom);
      if (
        (!shouldUpdateOnZoomParam && zoom && zoom < 19) ||
        (zoom && zoom > 19 && styleName === "pontos_sdl") ||
        (zoom && zoom <= 19 && styleName === "pontos_sdl_cluster")
      ) {
        clearTimeout(bouncing_timeout as number);
        bouncing_timeout = null;

        return;
      }
      if (zoom && zoom > 19) {
        updateWMS({
          layer: tileLayer,
          style: "pontos_sdl",
          options: {},
        });
      } else {
        updateWMS({
          layer: tileLayer,
          style: "pontos_sdl_cluster",

          options: {},
        });
      }
      clearTimeout(bouncing_timeout as number);
      bouncing_timeout = null;
    }, 600) as unknown as number;
  });

  return tileLayer;
};
export const addVectorLayer = (
  map: ol.Map,
  color: string = "blue",
  size = 10,
  id: number | null = null
) => {
  const circle = new Circle({ radius: size, fill: new Fill({ color: color }) });
  const style = new Style({ image: circle });
  const vectorLayer = new VectorLayer({ style });
  if (id) {
    vectorLayer.set("id", id);
  }
  map.addLayer(vectorLayer);

  return vectorLayer;
};

export const drawPoint = (e: Coordinate, layer: VectorLayer<any>) => {
  console.debug("coordinates", e);
  const point = new ol.Feature({ geometry: new Point(e) });
  const source = new VectorSource({ features: [point] });
  layer.setSource(source);
  return point;
};
