import { CSSProperties } from "react";

export default function RadioGroup({
  options,
  elementOptions,
  name,
  style,
  radioSize,
  value,
  onChange,
}: {
  options: Array<string>;
  name: string;
  elementOptions?: Record<string,JSX.Element>;
  style?: CSSProperties;
  radioSize?: number;
  value?: string | number;
  onChange: (value: string, index?: number) => void;
}) {
  const isChecked = (localValue?: string, index?: number) => {
    if (typeof index === "number" && +index === +(value as number)) {
        return true;
    }
    if (value === localValue) {
      return true;
    }
    return false;
  };
  return (
    <div style={style}>
      {options.map((op, index) => (
        <div
          style={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
        >
          <input
            id={"radio-" + name + op}
            type="radio"
            name={name}
            onClick={() => {
              onChange(op, index);
            }}
            checked={isChecked(op, index)}
            value={op}
            style={{
              WebkitTransform: `scale(${radioSize || 1.5})`,
              transform: `scale(${radioSize || 1.5})`,
            }}
          />

          <label htmlFor={"radio-" + name + op}>{elementOptions?.[index] || op}</label>
        </div>
      ))}
    </div>
  );
}
