import React from "react";
import { useLocation } from "react-router-dom";
import Logo from "../logo";

export default function Header() {
  const path = useLocation().pathname;
  console.log(path);
  return (
    <header
      style={{
        backgroundColor: "white",
        flex: 1,
        display: "flex",
        padding: "0.2rem",
        boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      <div
        style={{
          color: "#301934",
          display: "flex",
          flex: 1,
          fontWeight: 100,
          alignItems: "left",
          justifyContent: "left",

          flexDirection: "row",
        }}
      >
        <div style={{ marginLeft: "1rem" }}>
          <Logo size={0.7} />
        </div>
      </div>
    </header>
  );
}
