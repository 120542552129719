import * as React from "react";
import { SVGProps } from "react";

const PinGps = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 21 31"
    width="28"
    height="31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.77 7.866c.001-1.443.464-2.856 1.334-4.069.87-1.212 2.11-2.173 3.572-2.766A9.175 9.175 0 0 1 12.345.45c1.585.215 3.069.837 4.271 1.792 1.203.955 2.074 2.201 2.51 3.59a6.783 6.783 0 0 1-.058 4.242c-.472 1.38-1.376 2.607-2.604 3.535-1.228.929-2.727 1.519-4.318 1.699v10.37c0 .25-.11.488-.305.664-.196.176-.46.274-.737.274-.276 0-.541-.098-.736-.274a.892.892 0 0 1-.306-.663v-10.37c-2.014-.228-3.867-1.11-5.21-2.481C3.51 11.457 2.77 9.692 2.772 7.864v.002Zm5.195 16.077a.866.866 0 0 1-.18.698c-.161.202-.404.339-.677.38-1.483.221-2.666.553-3.448.924a2.761 2.761 0 0 0-.77.497.56.56 0 0 0-.12.169v.005l.005.015c.008.022.02.043.033.062.083.109.185.204.302.281.344.244.907.507 1.694.741 1.565.469 3.792.776 6.3.776 2.509 0 4.736-.305 6.3-.776.788-.236 1.35-.497 1.694-.74.118-.078.221-.173.304-.282a.263.263 0 0 0 .031-.062l.005-.015v-.007a.56.56 0 0 0-.12-.169 2.762 2.762 0 0 0-.77-.495c-.783-.371-1.965-.703-3.448-.924a1.12 1.12 0 0 1-.391-.123 1.01 1.01 0 0 1-.307-.25.9.9 0 0 1-.175-.338.846.846 0 0 1-.015-.372.885.885 0 0 1 .148-.348.99.99 0 0 1 .287-.27 1.155 1.155 0 0 1 .795-.149c1.604.24 3.025.616 4.077 1.115.96.455 2.002 1.205 2.002 2.33 0 .8-.542 1.41-1.134 1.832-.604.428-1.416.775-2.325 1.047-1.829.549-4.29.871-6.958.871-2.669 0-5.129-.322-6.958-.871-.909-.272-1.721-.62-2.325-1.047-.592-.422-1.133-1.033-1.133-1.832 0-1.123 1.041-1.875 2.002-2.33 1.052-.499 2.472-.876 4.077-1.114.272-.04.551.018.776.162a.931.931 0 0 1 .422.609Z"
      fill={props.color}
    />
  </svg>
);

export default PinGps;
