import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { BodyRelatorio, DadosRelatorio } from "../../types";
import { getRelatorios } from "./api";

export default function useRelatorio(props?: {
  body?: BodyRelatorio;
  query?: string;
  onSuccess?: (data: DadosRelatorio) => void;
}) {
  const { mutate, isError, isLoading } = useMutation(
    async ({ body, query }: { body: BodyRelatorio; query?: string }) =>
      await getRelatorios(body, query)
  );
  const [data, setData] = useState<DadosRelatorio>({
    areas: {},
    funcs: {},
  });
  useEffect(() => {
    console.log("atualizando", props);
    mutate(
      {
        body: props?.body || {
          areas: [],
          funcs: [],
        },
        query: props?.query || "",
      },
      {
        onSuccess: (dataAPI) => {
          console.log("onSuccess");
          setData(dataAPI);
          props?.onSuccess?.(dataAPI);
        },
      }
    );
    props?.onSuccess?.(data);
  }, [props?.body, props?.query]);
  async function buscarDados({
    body,
    query,
    onSuccess,
  }: {
    body: BodyRelatorio;
    query?: string;
    onSuccess: (data: DadosRelatorio) => void;
  }) {
    return mutate(
      {
        body,
        query,
      },
      {
        onSuccess,
      }
    );
  }
  return { buscarDados, data, isError, isLoading };
}
