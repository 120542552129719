import { AxiosError } from "axios";
import { useQuery as query, useMutation, useQueryClient } from "react-query";
import {
  DadosFuncionarios,
  DadosSemRelacao,
  EditFuncionario,
  PostFuncionario,
} from "../../types";
import {
  getFuncionarios as _getFuncionario,
  postFuncionario as _postFuncionario,
  deleteFuncionario as _deleteFuncionario,
  updateFuncionario as _updateFuncionario,
} from "./api";

export default function useFuncionarios(params?: {
  onSuccess: (data: Array<DadosFuncionarios>) => void;
}) {
  const queryClient = useQueryClient();
  const postFunctionarioMutate = useMutation(_postFuncionario);
  const deleteFuncionarioMutation = useMutation(_deleteFuncionario);
  const updateFuncionarioMutation = useMutation(_updateFuncionario);
  const { data, isLoading, isError } = query("funcionarios", _getFuncionario, {
    onSuccess: params?.onSuccess,
  });

  function postFuncionario(
    body: PostFuncionario,
    onSuccess?: () => void | Promise<void>,
    onError?: () => void | Promise<void>
  ) {
    try {
      const { data, isLoading, isError, mutate } = postFunctionarioMutate;
      mutate(body, {
        onSuccess,
        onError: (e: any) => {
          console.error(e?.response);
          onError?.();
        },
      });
      console.log(isError);
    } catch (e) {
      console.log(e);
    }
  }
  function deleteFuncionario(id: number) {
    const { mutate } = deleteFuncionarioMutation;
    mutate(id, {
      onSuccess: (deleteRowId) => {
        queryClient.setQueriesData("funcionarios", (old: any) => {
          const result = old.filter((row: DadosFuncionarios) => row.id !== id);
          return result;
        });
      },
    });
  }
  function updateFuncionario(
    body: EditFuncionario,
    onSuccess?: (newFunc?: DadosFuncionarios) => void | Promise<void>,
    onError?: (e?: any) => void | Promise<void>
  ) {
    try {
      const { data, isLoading, isError, mutate } = updateFuncionarioMutation;
      mutate(body, {
        onSuccess: (updatedFunc) => {
          queryClient.setQueryData("funcionarios", (old: any) => {
            return old.map((funcionario: DadosFuncionarios) => {
              if (funcionario.id === updatedFunc.id) {
                return updatedFunc;
              }
              return funcionario;
            });
          });
          onSuccess?.(updatedFunc);
        },
        onError: (e: unknown) => {
          if (e instanceof AxiosError) onError?.(e.response?.data.message);
          else onError?.();
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
  return {
    data,
    isLoading,
    isError,
    postFuncionario,
    deleteFuncionario,
    updateFuncionario,
  };
}
